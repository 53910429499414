import { Component, ElementRef, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DynamicElementsContainerComponent } from '@app/components/dynamic-elements-container/dynamic-elements-container.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
	selector: 'app-root',
	template: `<router-outlet>
		<div #appLoader class="app-loader"></div>
	</router-outlet>`,
	styles: [],
	providers: [DynamicElementsContainerComponent]
})
export class AppComponent {

	@ViewChild('appLoader', { static: true }) appLoader: ElementRef;

	constructor(
		private readonly _gtmService: GoogleTagManagerService,
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _viewContainerRef: ViewContainerRef,
		private _renderer: Renderer2,
		private _el: ElementRef
	) { }

	ngOnInit(): void {
		// @link https://github.com/mzuccaroli/angular-google-tag-manager/issues/169#issuecomment-1523515990
		this._gtmService.addGtmToDom();

		// Apply preloading on children routes only if user visits parent.
		// Deep modules and children control is not available under the PreloadingStrategy.
		// @link https://blog.devgenius.io/advanced-preloading-strategy-for-large-applications-in-angular-cd7ac2b763af
		// @link https://blog.bitsrc.io/preloading-strategies-boost-up-angular-app-loading-time-ffb19da63155
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// Remove the loader element from the background of code.
				if (this._el != null && this.appLoader != null) {
					this._renderer.removeChild(this._el?.nativeElement, this.appLoader?.nativeElement);
				}
				// if (this._activatedRoute.children) {
				// 	const childRoutes = this._activatedRoute.children;
				// 	childRoutes.forEach((childRoute) => {
				// 		childRoute.routeConfig.children?.forEach((route) => {
				// 			if ((! route.canActivate || ! route.canMatch) && route.loadChildren && route.data?.preload) {
				// 				route.loadChildren?.();
				// 			}
				// 		});
				// 	});
				// }
			}
		});
	}

	/**
	 * Create a general container for all dynamic elements rendering (ETT-190)
	 *
	 * Required in order to generalize the render of various elements outsize of the directive scope.
	 * Note: createComponent(componentFactory) actually inserts the component parallel to the host container.
	 * You will notice that we gave the ng-template a variable (appDynamicElementsContainer) which we will reference using @ViewChild().
	 * @link https://davembush.medium.com/dynamically-add-components-in-angular-7dc62b2a58d3
	 */
	ngAfterViewInit() {
		(async () => {
			// Get a reference to the host container.
			const containerRef = this._viewContainerRef;
			const { DynamicElementsContainerComponent } = await import('@app/components/dynamic-elements-container/dynamic-elements-container.component');
			// Create the component using the host reference.
			containerRef.clear();
			containerRef.createComponent(DynamicElementsContainerComponent);
		})();
	}
}
