/* eslint-disable */
export const environment = {
	envName: 'TEST',
	production: false,
	debugMode: true,
	config: {
		indexPage: `${window.location.origin}`,
		apiUrl: '//dashboard.test.eljininc.biz/api',
		appVersion: require('../../package.json').version,
		cookieDomain: 'dashboard.test.eljininc.biz'
	},
	googleConfig: {
		googleTag: 'GTM-W87L5ZF'
	},
	auth0: {
		domain: 'auth.test.eljininc.biz',             // Auth0 Single Page App Domain.
		authDomain: 'https://auth.test.eljininc.biz', // Auth0 Single Page App Domain URL.
		clientId: 'hCpIXa2R3M1AZ78jsPgFAKDzfywPfLty', // Auth0 Single Page App Client ID.
		clientUrl: `${window.location.origin}`,       // Auth0 Single Page App Client URL + baseHref URL.
		audience: 'https://api.test.eljininc.biz',    // Auth0 API URL.
		landingPageUrl: `https://test.eljininc.biz`,  // Auth0 Logout URL.
		responseType: 'token id_token',
		dbConnectionName: 'Username-Password-Authentication' // TypeORM.
	},
	paymentGateway: {
		stripePKToken: 'pk_test_unp2WZVJi0r1JjzeUzISOoF5',
	},
	seo: {
		separator: ' - ',
		siteName: 'Test platform - Eljin, Inc',
		keywords: 'union payments, audiobooks, payments, transfers, international transfers, budgeting and analytics, security'
	}
};
