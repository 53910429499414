import { Profile } from '@feature/profiles/shared/profile.model';
import { CustomFieldValue } from '@app/components/custom-fields/shared/custom-field.model';

// ETT-133 Order of roles is important for their priorities match.
export enum CompanyMemberRole {
	owner = 'owner',
	admin = 'admin',
	editor = 'editor',
	viewer = 'viewer'
}

export enum CompanyMemberRequestStatus {
	inProgress = 'in_progress',
	reSent = 're_sent',
	accepted = 'accepted',
	declined = 'declined',
	deactivate = 'deactivate' // ETT-424 Special status to keep enum commands clear and not use string.
}

export enum CompanyType {
	all = 'all',
	customer = 'customer',
	agent = 'agent',
	talent = 'talent'
}

export class Company {
	id: number;

	ownerId: number;
	ownerFirstName: string;
	ownerMiddleName: string;
	ownerLastName: string;
	ownerFullName: string;
	ownerEmail: string;

	name: string;
	description: string;
	timeZone: string;
	status: boolean;
	isEnterprise: boolean;

	type: CompanyType;
	profile: Profile;

	get isAgent(): boolean {
		return this.type !== undefined && this.type.toString() === CompanyType[CompanyType.agent];
	}
	get isCustomer(): boolean {
		return this.type !== undefined && this.type.toString() === CompanyType[CompanyType.customer];
	}
}

export class CompanyPair {
	id: number;

	ownerId: number;
	ownerFirstName: string;
	ownerMiddleName: string;
	ownerLastName: string;
	ownerFullName: string;
	ownerEmail: string;

	name: string;
	description: string;
	timeZone: string;
	status: boolean;
	isEnterprise: boolean;

	type: CompanyType;
	address?: never;
	customFieldValues?: Array<CustomFieldValue>;
}

export class CompanyMemberRef {
	id: number;

	requestKey: string;

	companyId: number;
	companyName: string;
	companyOwnerId: number;
	companyOwnerEmail: string;
	companyProfileId: number;
	companyProfileSignupStep?: string;
	companyType: CompanyType;
	companyIsEnterprise: boolean;

	senderId: number;
	senderFirstName: string;
	senderMiddleName: string;
	senderLastName: string;
	senderFullName: string;
	senderEmail: string;

	memberId?: number;
	memberFirstName: string;
	memberMiddleName: string;
	memberLastName: string;
	memberFullName: string;
	memberEmail: string;
	memberIsNew: boolean;

	memberRole: CompanyMemberRole;
	status: CompanyMemberRequestStatus;
	initialPopup: boolean;

	updatedDate: Date;

	get isAgent(): boolean {
		return this.companyType !== undefined && this.companyType?.toString() === CompanyType[CompanyType.agent];
	}
	get isCustomer(): boolean {
		return this.companyType !== undefined && this.companyType?.toString() === CompanyType[CompanyType.customer];
	}
	get signupStep(): string {
		return this.companyProfileSignupStep;
	}
}
