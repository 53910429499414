import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

/**
 * Setting page titles natively with the Angular Router
 * @link https://medium.com/@david_shortman/how-to-use-dynamic-titles-from-ngrx-selectors-52271bfbc986
 * @link https://www.thisdot.co/blog/how-to-update-the-application-title-based-on-routing-changes-in-angular
 * @link https://dev.to/brandontroberts/setting-page-titles-natively-with-the-angular-router-393j
 * @link https://yoast.com/help/list-available-snippet-variables-yoast-seo/#basic-variables
 */
@Injectable({ providedIn: 'root' })
export class TemplateTitleStrategy extends TitleStrategy {

	constructor(private readonly title: Title) {
		super();
	}

	override updateTitle(routerState: RouterStateSnapshot) {
		// Get the title using the base method.
		const title = this.buildTitle(routerState);
		this.title.setTitle(`${environment.seo.siteName}${environment.seo.separator}${title}`);
	}
}
