import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import { WidgetDatePeriod } from '@shared/widgets/shared/widget.model';

@Injectable({ providedIn: 'root' })
export class UtilsService {
	/** *********************************** Constructor ************************************** */

	constructor(
		// @see {@link https://www.geeksforgeeks.org/angular10-getlocaleid-function/}
		// @see {@link https://dev.to/danielpdev/how-to-internationalize-dates-in-angular-24hm}
		@Inject(LOCALE_ID) public locale: string
	) {}

	/** ************************************* Methods **************************************** */

	/**
	 * Generates a unique identifier
	 * @param {any} [a] - Optional parameter for generating the unique identifier.
	 * @returns {string} The generated unique identifier.
	 */
	public uuid(a?: any): string {
		return a
			// eslint-disable-next-line no-bitwise,no-magic-numbers
			? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
			// eslint-disable-next-line no-magic-numbers
			: String(1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.uuid);
	}

	/**
	 * Gets the masked email
	 * @param {string} email - The email address to process.
	 * @returns {string} The masked email address.
	 */
	public getMaskedEmail(email: string): string {
		let emails: Array<any> = [];
		emails.push('N/A');
		if (email && email?.length > 0) emails = email.split(';');
		return emails[0];
	}

	/**
	 * Gets the unmasked email
	 * @param {string} email - The email address to process.
	 * @returns {string} The unmasked email address.
	 */
	public getUnmaskedEmail(email: string): string {
		let emails: Array<any> = [];
		emails.push('');
		if (email && email?.length > 0) emails = email.split(';');
		// eslint-disable-next-line no-magic-numbers
		if (emails?.length === 2) return emails[1];
		return '';
	}

	/**
	 * Generates a random ID
	 * @param {number} [length=9] - The length of the generated ID (optional, default is 9).
	 * @returns {string} The randomly generated ID.
	 */
	public generateId(length: number = 9): string {
		return Math.random().toString(36).substring(2, length);
	}

	/**
	 * Parses an ISO8601 date format to a Date format compatible with any browser
	 *
	 * @see {@link https://stackoverflow.com/a/20223090}
	 * @param {string} isoDateString - The ISO8601 date string to parse.
	 * @returns {Date | null} The parsed Date object, or null if the input is invalid.
	 */
	public dateFromISO8601(isoDateString: string): Date {
		if (isoDateString == null) return null;
		const parts = isoDateString.match(/\d+/g);
		const isoTime = Date.UTC(
			parseInt(parts[0], 10),
			parseInt(parts[1], 10) - 1,
			parseInt(parts[2], 10),
			parseInt(parts[3], 10),
			parseInt(parts[4], 10),
			parseInt(parts[5], 10)
		);
		const isoDate = new Date(isoTime);
		return isoDate;
	}

	/**
	 * Test if the given string is date only, without timestamp
	 *
	 * @desc The first part is a date (1970-01-01), the second is the time (12:00:00.000Z).
	 * @see {@link {API/OrderImportService/stringToDate}} Match one of the date formats without a time component:
	 * @see {@link https://dev.to/shubhampatilsd/removing-timezones-from-dates-in-javascript-46ah}
	 * Group 1: (\d{4}-\d{2}-\d{2}) matches the yyyy-MM-dd format.
	 * Group 2: (\d{2}\/\d{2}\/\d{4}) matches the dd/MM/yyyy format.
	 * Group 3: (\d{2}-\d{2}-\d{4}) matches the MM-dd-yyyy format.
	 * Group 4: (\d{2}\.\d{2}\.\d{4}) matches the MM.dd.yyyy format.
	 * @param {string} inputDateString - The date string, without timestamp
	 * @returns {boolean}
	 */
	public isDateStringWithoutTimeStamp(inputDateString: string): boolean {
		const dateOnlyRegex = /^(?:(\d{4}-\d{2}-\d{2})|(\d{2}\/\d{2}\/\d{4})|(\d{2}-\d{2}-\d{4})|(\d{2}\.\d{2}\.\d{4}))$/;
		return dateOnlyRegex.test(inputDateString);
	}

	/**
	 * Converts a given date string or Date object to UTC without timezone
	 *
	 * @param {string | Date} inputDateString - The date string or Date object to convert to UTC.
	 * @param {string | Date} [type = Date] - Type of converted object, string or Date. By default, the Date object is used.
	 * @returns {string | Date} The converted UTC date string or Date object without the timezone.
	 * @throws {Error} If the input is neither a string nor a Date object.
	 *
	 * @see {@link Core/Services/BaseHttpService}
	 * @see {@link https://dev.to/shubhampatilsd/removing-timezones-from-dates-in-javascript-46ah}
	 * @see {@link https://medium.com/@novosibcool/typescript-function-generic-by-return-type-ed8af19f7b2e}
	 */
	public dateToUTCWithoutTimezone(
		inputDateString: string | Date,
		type: { new(): string | Date } = Date as any
	): any {
		if (! inputDateString) return null as any;

		// Validate input type.
		if (typeof inputDateString !== 'string' && ! (inputDateString instanceof Date)) {
			throw new Error('Invalid input: inputDateString must be a string or Date object.');
		}

		// Convert Date object to ISO string if needed.
		let dateString = inputDateString instanceof Date ? inputDateString.toISOString() : inputDateString.toString();

		// Check if the date string is date-only and append time if needed.
		if (this.isDateStringWithoutTimeStamp(dateString)) {
			dateString = `${dateString}T00:00:00.000`;
		}

		const inputDate: Date = new Date(dateString); // Parse the date string to a Date object.
		const tzOffset: number = inputDate.getTimezoneOffset() * 60000; // Offset in milliseconds.
		// Get a date in the ISO 8061 format (toISOString), in the UTC time, e.g. 1970-01-01T12:00:00.000Z.
		// A date object with the given ISO string is automatically converted to the current time zone.
		// However, if the Z is removed from the end of the ISO8601 string, the time zone is not converted automatically.
		const utcDateWithoutTimezone: string = new Date(inputDate.valueOf() - tzOffset).toISOString().slice(0, -1);

		// If the type is Date, convert the string back to a Date object. Otherwise, return the T based string.
		return ((type as any).name === 'Date') ? new Date(utcDateWithoutTimezone) : utcDateWithoutTimezone;
	}

	/**
	 * Generates a random title using a predefined words vocabulary
	 * @param {number} [length] - The length of the title to generate (optional).
	 * @returns {string} The randomly generated title.
	 * @see {@link https://stackoverflow.com/questions/67962647/make-this-javascript-random-word-generator-work-on-many-divs-within-the-same-doc}
	 */
	public randomTitleGenerator(length?: number): string {
		const randomWords = [
			'Apple',
			'Impossible',
			'Bride',
			'Saturn',
			'Kay Rowling',
			'Bestseller',
			'The Woman',
			'Daughter',
			'Smokes',
			'Scoundrel',
			'Heart',
			'Crooked',
			'Code',
			'Yesterday',
			'Bound',
			'Corpse',
			'The Brute',
			'House'
		];

		let title = '';
		// eslint-disable-next-line no-magic-numbers
		let titleLength = length || Math.floor(Math.random() * 10);
		if (titleLength <= 0) { titleLength = 1; }

		for (let i = 1; i <= titleLength; i++) {
			const randomNum = Math.floor(Math.random() * (randomWords?.length));
			title += ' ' + randomWords[randomNum];
		}
		return title;
	}

	/**
	 * Gets the current page URL without query parameters using vanilla JavaScript
	 * @param {string} url - The URL to process.
	 * @returns {null | string} The URL without query parameters, or null if the input is invalid.
	 */
	public getCurrentUrlWithoutQueryParamsVanillaJS(url: string): null | string {
		if (url == null || url?.length <= 0) return null;
		let newUrl = url;
		if (newUrl.includes('?')) newUrl = newUrl.split('?')[0];
		if (! newUrl.startsWith('/')) newUrl = `/${newUrl}`;
		return newUrl;
	}

	/**
	 * Converts a camelCase string to snake_case
	 * @param {string} camelCaseString - The camelCase string to convert.
	 * @returns {string | null} The converted snake_case string, or null if the input is null.
	 */
	public convertCamelCaseToSnakeCase(camelCaseString: string): string | null {
		if (camelCaseString == null) return null;
		return camelCaseString.toLowerCase().replace(/[A-Z]/g, letter => `_${letter}`);
	}

	/**
	 * Converts a snake_case string to camelCase
	 * @param {string} snakeCaseString - The snake_case string to convert.
	 * @returns {string | null} The converted camelCase string, or null if the input is null.
	 */
	public convertSnakeCaseToCamelCase(snakeCaseString: string): string | null {
		if (snakeCaseString == null) return null;
		return snakeCaseString.replace(/([-_][a-z])/g, group =>
			group
				.toUpperCase()
				.replace('-', '')
				.replace('_', '')
		);
	}

	/**
	 * Capitalizes the first letter of each word in the given string
	 * @param {string} source - The string to capitalize.
	 * @param {string | RegExp} [separator=' '] - The separator used to split the words (default is space).
	 * @returns {string} The string with the first letter of each word capitalized.
	 */
	public capitalizeWords(source: string, separator: string | RegExp = ' '): string {
		const words: Array<string> = source.split(separator);
		words.forEach((word: string, index: number) => words[index] = words[index][0].toUpperCase() + words[index].substring(1));
		return words.join(' ');
	}

	/**
	 * Capitalizes the first letter of the given string
	 * @param {string} source - The string to modify.
	 * @param {string} [letterCase='uppercase'] - The desired case for the first letter ('uppercase' or 'lowercase').
	 * @returns {string} The string with the first letter capitalized according to the specified case.
	 */
	public changeCaseForFirstLetterInSentence(source: string, letterCase = 'uppercase'): string {
		const firstLetter = ((letterCase === 'uppercase') ? source[0].toUpperCase() : source[0].toLowerCase());
		return firstLetter + source.substring(1);
	}

	/**
	 * Creates an HTML element required for a confirmation dialog
	 * @param {string} content - The content to be displayed in the HTML element.
	 * @returns {HTMLElement} The created HTML element.
	 */
	public createHTMLElement(content: string): HTMLElement {
		const divElement: HTMLDivElement = document.createElement('div');
		divElement.innerHTML = content;
		divElement.setAttribute('class', 'xs-padding-bottom-8');
		return divElement;
	}

	/**
	 * Converts a size in bytes to KB, MB, GB, etc.
	 * @desc Usage examples:
	 * - formatBytes(1024)       // 1 KiB
	 * - formatBytes('1024')     // 1 KiB
	 * - formatBytes(1234)       // 1.21 KiB
	 * - formatBytes(1234, 3)    // 1.205 KiB
	 * - formatBytes(0)          // 0 Bytes
	 * - formatBytes('0')        // 0 Bytes
	 * @param {number | string} bytes - The size in bytes to convert.
	 * @param {number} [decimals=2] - (Optional) The number of decimal places to round to (optional, default is 2).
	 * @returns {string} The formatted size with appropriate unit.
	 * @see {@link https://stackoverflow.com/a/18650828}
	 */
	public formatBytes(bytes: number | string, decimals = 2): string {
		if (isNaN(Number(bytes))) return '0 Bytes';

		bytes = Number(bytes);
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}

	/**
	 * Gets the current year
	 * @returns {number} The current year.
	 */
	public getCurrentYear(): number { return new Date().getFullYear(); }

	/**
	 * Gets the previous year
	 * @param {Date} startDate - (Optional) The start date as an entry point.
	 * @returns {number} The previous year.
	 */
	public getPreviousYear(startDate?: Date): number {
		startDate = (startDate == null ? new Date() : startDate);
		return startDate.getFullYear() - 1;
	}

	/**
	 * Gets the current date
	 * @param {string} [format] - The format to return the date in (optional).
	 * @returns {string | Date} The current date as a string or Date object based on the format provided.
	 */
	public getCurrentDate(format?: string): string | Date {
		if (format == null) return new Date();
		return formatDate(new Date(), format, this.locale);
	}

	/**
	 * Gets the previous date based on the specified date period
	 * @param {WidgetDatePeriod} datePeriod - The date period to calculate the previous date for.
	 * @param {string} [format] - The format to return the date in (optional).
	 * @returns {string | Date} The previous date as a string or Date object based on the format provided.
	 */
	public getPreviousDate(datePeriod: WidgetDatePeriod, format?: string): string | Date {
		const prevDate = new Date();
		switch (datePeriod) {
			case WidgetDatePeriod.oneYear:
			default:
				prevDate.setFullYear(prevDate.getFullYear() - 1);
				break;
		}
		if (format == null) return prevDate;
		return formatDate(prevDate, format, this.locale);
	}

	/**
	 * Generate list of month names
	 * @see {@link https://dev.to/pretaporter/how-to-get-month-list-in-your-language-4lfb}
	 * @see {@link https://codingbeautydev.com/blog/javascript-get-month-short-name/}
	 */
	public getMonthList(format: 'long' | 'short' = 'long', locales?: string | Array<string>): Array<string> {
		const year = new Date().getFullYear(); // E.g. 2023
		const monthList: Array<number> = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
		const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(locales, {
			month: format
		});
		// eslint-disable-next-line func-style
		const getMonthName = (monthIndex: number) => {
			const dateFormatted = formatter.format(new Date(year, monthIndex));
			// Date formatting is inconsistent with most programming languages and localization.
			// Make sure that the shortened month name contains only three characters.
			// @see {@link https://github.com/moment/moment/issues/2873}
			return format !== 'short' ? dateFormatted : dateFormatted.substring(0, 3);
		};
		return monthList.map(getMonthName);
	}

	/**
	 * Generate Date based on the given date string
	 * @param {string} dateString - The date as a string.
	 * @param {WidgetDatePeriod} datePeriod - The date period to calculate the previous date for.
	 * @param {boolean} [includePrevPeriod=false] (Optional) If true, the date period has to be calculated for the previous period.
	 *
	 * @see {@link https://www.calculator.net/date-calculator.html}
	 * @see {@link https://stackoverflow.com/a/29052008}
	 * @see {@link https://stackoverflow.com/a/50345788}
	 */
	public getDateInterval(dateString: string, datePeriod: WidgetDatePeriod, includePrevPeriod = false): Date {
		if (dateString == null) return null;
		const dateFromString: Date = new Date(dateString);
		// eslint-disable-next-line default-case
		switch (datePeriod) {
			case WidgetDatePeriod.thirtyDays:
				dateFromString.setDate(dateFromString.getDate() - 30);
				return dateFromString;
			case WidgetDatePeriod.sixtyDays:
				dateFromString.setDate(dateFromString.getDate() - 60);
				return dateFromString;
			case WidgetDatePeriod.oneMonth:
				return ! includePrevPeriod
					? new Date(dateFromString.getFullYear(), dateFromString.getMonth() - 1, dateFromString.getDate())
					: new Date(dateFromString.getFullYear(), dateFromString.getMonth() - 2, dateFromString.getDate());
			case WidgetDatePeriod.oneYear:
				return ! includePrevPeriod
					? new Date(dateFromString.getFullYear() - 1, dateFromString.getMonth(), dateFromString.getDate())
					: new Date(dateFromString.getFullYear() - 1, this.getDateQuarter(dateFromString) * 3, 1);
			case WidgetDatePeriod.ytd:
				return ! includePrevPeriod
					? new Date(`${dateFromString.getFullYear()}-01-01 00:00:00`)
					: new Date(`${dateFromString.getFullYear() - 1}-01-01 00:00:00`);
			case WidgetDatePeriod.fiveYears:
				dateFromString.setFullYear(dateFromString.getFullYear() - 5);
				return new Date(`${dateFromString.getFullYear()}-01-01 00:00:00`);
		}

		return null;
	}

	/**
	 * Determines the quarter that the given date falls into
	 * @desc
	 * - January 1st to March 31st: First Quarter
	 * - April 1st to June 30th: Second Quarter
	 * - July 1st to September 30th: Third Quarter
	 * - October 1st to December 31st: Fourth Quarter
	 * @param {Date} [date=new Date()] - The date to determine the quarter for.
	 * @returns {number} The quarter number (0-3) representing the quarter of the year.
	 * @see {@link https://bobbyhadz.com/blog/javascript-get-date-quarter}
	 */
	public getDateQuarter(date: Date = new Date()): number {
		return Math.floor(date.getMonth() / 3);
	}

	/**
	 * Determines the number of days in a given month
	 * @note Month in JavaScript is 0-indexed, e.g. January is 0, February is 1, etc.
	 * @param {string} month - The month as a string (0-indexed).
	 * @param {string} year - The year as a string.
	 * @returns {number} The number of days in the specified month and year.
	 * @see {@link https://stackoverflow.com/a/1184359}
	 */
	public getDaysInMonth(month: string, year: string) {
		return new Date(parseInt(year, 10), parseInt(month, 10) + 1, 0).getDate();
	}

	/**
	 * Generates a series of dates in Angular with a specified format for a given time interval
	 * @desc Usage examples:
	 * - Generate series starting from a past date period:
	 *   this.generateSeries(periodStartDate, currentDate, 'MMM YYYY');
	 * - Reset the series and generate for the current year:
	 *   this.generateSeries(new Date(currentDate.getFullYear(), 0, 1), currentDate, 'MMM YYYY');
	 * @param {Date} startDate - The start date of the series.
	 * @param {Date} endDate - The end date of the series.
	 * @param {string} [format] - The format to apply to the dates (optional).
	 * @returns {Array<string>} An array of formatted dates within the specified interval.
	 * @see {@link https://angular.io/api/common/DatePipe#pre-defined-format-options}
	 */
	public generateSeries(startDate: Date, endDate: Date, format?: string): Array<string> {
		const dateSeries: Array<string> = [];
		const currentDate: Date = new Date(startDate);
		// eslint-disable-next-line no-unmodified-loop-condition
		while (currentDate <= endDate) {
			const formattedDate = (format != null && format?.length > 0 ? formatDate(currentDate, format, this.locale) : currentDate.toISOString());
			dateSeries.push(formattedDate);
			currentDate.setMonth(currentDate.getMonth() + 1);
		}
		return dateSeries;
	}

	/**
	 * Calculates the difference between two dates in various units
	 * @param {Date} dateStart - The starting date for the calculation.
	 * @param {Date} dateEnd - The ending date for the calculation.
	 * @param {'years' | 'months' | 'days' | 'hours' | 'minutes'} unit - The unit of time to calculate the difference in.
	 * @returns {number} The calculated difference between the dates in the specified unit.
	 */
	public calculateDateDifference(dateStart: Date, dateEnd: Date, unit: 'years' | 'months' | 'days' | 'hours' | 'minutes'): number {
		const timeDifference = dateStart.getTime() - dateEnd.getTime();
		switch (unit) {
			case 'years':
				return timeDifference / (365.25 * 24 * 60 * 60 * 1000);
			case 'months':
				return timeDifference / (30.44 * 24 * 60 * 60 * 1000);
			case 'days':
				return timeDifference / (24 * 60 * 60 * 1000);
			case 'hours':
				return timeDifference / (60 * 60 * 1000);
			case 'minutes':
				return timeDifference / (60 * 1000);
			default:
				throw new Error('Invalid dates');
		}
	}

	/**
	 * Generates a non-repeating random number within a specified range
	 * @param {number} min - The minimum value for the random number (inclusive).
	 * @param {number} max - The maximum value for the random number (exclusive).
	 * @returns {number} A non-repeating random number within the specified range.
	 * @see {@link https://stackoverflow.com/a/55986745}
	 */
	public getRandomNumber(min: number, max: number) {
		const chosenNumbers: Set<any> = new Set();
		let num;
		do {
			num = Math.round(Math.random() * (max - min) + min);
		} while (chosenNumbers.has(num));
		chosenNumbers.add(num);
		return num;
	}

	/**
	 * Converts a Hex color code to RGBA format.
	 * @desc Usage:
	 * - hexToRGB('#af087b', .5) // returns: rgba(175,8,123,0.5)
	 * - hexToRGB('af087b', .5)  // returns: rgba(175,8,123,0.5)
	 * - hexToRGB('af087b')      // returns: rgba(175,8,123,1)
	 * @param {string} hex - The Hex color code to convert.
	 * @param {number} [alpha=1] - The alpha value for the RGBA color (optional, default is 1).
	 * @returns {string} The RGBA color string.
	 * @see {@link https://stackoverflow.com/questions/21646738/convert-hex-to-rgba}
	 */
	public hexToRGB(hex, alpha = 1): string {
		const [r, g, b] = hex.match(/[0-9A-Fa-f]{2}/g).map(x => parseInt(x, 16));
		return `rgba(${r},${g},${b},${alpha})`;
	}

	/**
	 * Group the given object by the property specified
	 * @see {@link https://stackoverflow.com/a/64489535}
	 */
		// eslint-disable-next-line no-sequences
	public groupBy = (x, f) => x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});

	/**
	 * Returns an array of invalid control/group names, or a zero-length array if
	 * no invalid controls/groups where found
	 * @desc Although the form fields are valid, the form remains invalid in terms of both status and the valid flag.
	 * @param {FormGroup | FormArray} formToInvestigate - The form group or form array to check for invalid controls.
	 * @returns {Array<string>} An array of invalid control/group names.
	 * @see {@link https://stackoverflow.com/a/52312518/15391077}
	 */
	public collectInvalidControls(formToInvestigate: FormGroup | FormArray): Array<string> {
		const invalidControls: Array<string> = [];
		// eslint-disable-next-line func-style
		const recursiveFunc = (form: FormGroup | FormArray) => {
			Object.keys(form.controls).forEach((controlName: string) => {
				const control = form.get(controlName);
				if (control.invalid) invalidControls.push(controlName);
				if (control instanceof FormGroup || control instanceof FormArray) {
					recursiveFunc(control);
				}
			});
		};
		recursiveFunc(formToInvestigate);

		return invalidControls;
	}
}
