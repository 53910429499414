import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
	enableProdMode();
}

function bootloader(main: any) {
	if (document.readyState === 'complete') main();
	else document.addEventListener('DOMContentLoaded', main);
}

function main() {
	return platformBrowserDynamic().bootstrapModule(AppModule)
		.then(module => {
			// Ensure Angular destroys itself on hot reloads.
			// eslint-disable-next-line
			if (window['ngRef']) window['ngRef'].destroy();
			// eslint-disable-next-line
			window['ngRef'] = module;
			enableDebugTools(module.injector.get(ApplicationRef).components[0]);
		})
		.catch(err => console.error(err));
}

// @link https://danielk.tech/home/heres-how-you-can-improve-angular-change-detection-performance
// @link https://github.com/Tim-mhn/angular-universal-hmr/blob/master/src/main.ts
// @link https://github.com/angular/universal/issues/2218
bootloader(main);
