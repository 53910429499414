import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

/**
 * A return type that the child needs to return
 * @note Important to not export it, only we should be able to create it.
 * @see {@link https://stackoverflow.com/a/54985080}
 * @see {@link @link https://stackoverflow.com/a/57146137}
 */
class REQUIRED_SUPER { }

@Component({ template: '' })
export abstract class BaseHttpComponent implements OnDestroy {

	private subscriptions: Subscription;

	// Primitive datatype is immutable, so its state change is not recognized in a template.
	// Tell Angular to subscribe to it until the root variable to be checked.
	// @see {@link https://blog.thoughtram.io/angular/2016/02/22/angular-2-change-detection-explained.html}
	// @see {@link https://stackoverflow.com/a/48605880}
	public isLoading$: BehaviorSubject<boolean>;

	get isLoading(): boolean { return this.isLoading$?.value; }
	set isLoading(isLoading: boolean) { this.isLoading$.next(isLoading); }

	protected constructor() {
		this.subscriptions = new Subscription();
		this.isLoading$ = new BehaviorSubject<boolean>(false);

		// This method does not work for some reason
		// let f = this.ngOnDestroy;
		// this.ngOnDestroy = () => {
		//   f();
		//   this.unsubscribeAll();
		// };
	}

	/**
	 * Safely collect the subscriptions
	 * @see {@link https://stacksandfoundations.wordpress.com/2016/06/24/using-class-inheritance-to-hook-to-angular2-component-lifecycle/}
	 * @param {Subscription} sub - The subscription to add.
	 * @returns {Subscription}
	 * @protected
	 */
	protected safeSubscription(sub: Subscription): Subscription {
		if (! sub.closed) this.subscriptions.add(sub);
		return sub;
	}

	/**
	 * Safely unsubscribe from the collected subscriptions
	 * @private
	 */
	private unsubscribeAll() {
		this.subscriptions.unsubscribe();
	}

	ngOnDestroy(): REQUIRED_SUPER {
		this.unsubscribeAll();
		return new REQUIRED_SUPER();
	}
}
