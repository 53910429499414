import { Injectable, OnDestroy, Output } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { Theme, IsLoadingType, ThemeMode } from '@app/models/theme.model';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({ providedIn: 'root' })
export class ThemeService implements OnDestroy {
	/** *********************************** Declarations ************************************* */

	private _currentDate: string = Date();
	private _currentApplicationVersion: string = environment.config.appVersion;
	private _production: boolean = environment.production;
	private _isLoadingElements: IsLoadingType = {} as IsLoadingType;
	// Theme.
	private _activeTheme: Theme | undefined;
	private _availableThemes: Array<Theme>;

	/** ********************************* Getters/Setters ************************************ */

	get currentApplicationVersion(): string { return this._currentApplicationVersion; }
	get currentDate(): string { return this._currentDate; }
	get production(): boolean { return this._production; }
	get themeModes(): typeof ThemeMode { return ThemeMode; }
	@Output() get activeTheme(): Theme | undefined { return this._activeTheme; }
	set activeTheme(theme: Theme | undefined) { this._activeTheme = theme; }
	get availableThemes(): Array<Theme> { return this._availableThemes; }
	get isLoadingElements(): IsLoadingType { return this._isLoadingElements; }

	/** *********************************** Constructor ************************************** */

	constructor(private _router: Router) {
		// Init themes.
		this._availableThemes = [
			// 'name' has to match the $theme-colors() from the styles.scss
			{ name: 'eljin-light-theme', mode: ThemeMode.Light, active: false },
			{ name: 'eljin-dark-theme', mode: ThemeMode.Dark, active: false },
		];

		// Init the default theme.
		// if (this._router.url.indexOf('dashboard') < 0) {
		// Place the current theme to the <body> attribute, in case it's not a dashboard.
		if (this.availableThemes[0]) {
			this.activeTheme = this.availableThemes[0];
			this.activeTheme.active = true;
		}
		// }
	}

	/** ************************************* Methods **************************************** */

	/**
	 * Change the content layout class of the tag. The <html> HTML tag is used by default.
	 * @param {string} layoutType Name of the layout.
	 * @param {string} [action] Add/remove action (default is add).
	 * @param {string} [tagName] Name of the tag.
	 */
	public changeContentLayoutClass(layoutType: string, action?: string, tagName?: string): void {
		if (! action) action = 'add';
		if (! tagName) tagName = 'html';

		const tag = document.getElementsByTagName(tagName)[0];
		if (tag) {
			switch (action) {
				case 'add':
					tag.classList.add(layoutType + '-layout');
					break;
				case 'remove':
					tag.classList.remove(layoutType + '-layout');
					break;
				// Skip default
			}
		}
	}

	/**
	 * Change the theme class of the tag. The <body> HTML tag is used by default.
	 * @param {Theme} theme - Pointer on the Theme object.
	 * @param {string} [action] - Add/remove action (default is add).
	 * @param {string} [tagName] - Name of the tag.
	 */
	public changeThemeClass(theme: Theme, action?: string, tagName?: string): void {
		if (! action) action = 'add';
		if (! tagName) tagName = 'body';

		const tag = document.getElementsByTagName(tagName)[0];
		if (tag) {
			// @note Never use eval(): https://mzl.la/2A3sNzp
			// Function('tag.classList.' + action + '(\'bg-' + theme.name + '\')')();
			// Function('tag.' + actionForAttribute + 'Attribute(\'data-mode\', \'' + theme.mode + '\')')();

			// Change mode of the Theme (light, dark) as an attribute.
			switch (action) {
				case 'add':
					// Remove the current active theme.
					this.changeThemeClass(this.activeTheme, 'remove');
					// Assign new theme.
					tag.classList.add(theme.name);
					if (ThemeMode[theme.mode] && tagName === 'body') {
						tag.setAttribute('data-theme-name', theme.name.toString().toLowerCase());
						tag.setAttribute('data-theme-mode', ThemeMode[theme.mode].toString().toLowerCase());
					}
					break;
				case 'remove':
					tag.classList.remove(theme.name);
					tag.removeAttribute('data-theme-name');
					tag.removeAttribute('data-theme-mode');
					break;
				// Skip default
			}
		}
	}

	/**
	 * Change the theme
	 * @param {Event} [event] - An event which takes place in the DOM.
	 */
	public onThemeChange(event?: Event) {
		// @note (event.target as HTMLInputElement)?.checked
		if (this.activeTheme) {
			// Set the current theme's active param as not active,
			// because we need to make the opposite theme mode as active.
			this.changeThemeClass(this.activeTheme, 'remove');

			// Search for the first occurrence of the opposite to the current theme name.
			// @note Solution made regarding possibility of only two theme modes allowed.
			this.activeTheme = this.availableThemes.find((theme) => {
				// Return ThemeMode[theme.mode] !== ThemeMode[this.activeTheme.mode];
				return theme.name !== this.activeTheme.name;
			});

			// Set the new theme as active.
			this.activeTheme.active = true;
			// Update the <body> tag class and mode attribute.
			this.changeThemeClass(this.activeTheme);
		}
	}

	/**
	 * Checks the current theme with a corresponding ThemeMode value (e.g. Light, Dark etc.)
	 * @param {ThemeMode} themeMode - The ThemeMode enum value to check against.
	 * @param {string} [trueClass]
	 * @param {string} [falseClass]
	 * @returns {string | boolean}
	 */
	public isThemeMode(themeMode: ThemeMode, trueClass?: string, falseClass?: string): string | boolean {
		if (this.activeTheme == null) {
			return falseClass !== undefined ? falseClass : false;
		}

		return this.themeModes[this.activeTheme.mode].toLowerCase() === this.themeModes[themeMode].toLowerCase()
			? trueClass != null ? trueClass : true
			: falseClass != null ? falseClass : false;
	}

	/**
	 * Custom getter for the active theme mode name
	 * @param {boolean} toLowerCase - Cast to lower case.
	 * @returns {string}
	 */
	public getActiveThemeMode(toLowerCase?: boolean): string {
		if (this.activeTheme == null) return '';
		return toLowerCase ? this.themeModes[this.activeTheme.mode].toLowerCase() : this.themeModes[this.activeTheme.mode];
	}

	public getTheme(themeToFind: string) {
		return this._availableThemes.find((theme: Theme) => theme.name === themeToFind);
	}

	/** Custom getter for searching by key in the list of loading elements */
	public getLoadingElement(key: string | number): boolean {
		if (! Object.prototype.hasOwnProperty.call(this._isLoadingElements, key)) return false;
		return this._isLoadingElements[key];
	}
	/** Custom setter for assigning the loading element status by the key */
	public setLoadingElement(key: string, isLoading: boolean) {
		this._isLoadingElements[key] = isLoading;
	}

	/** ************************************* Destroy **************************************** */

	ngOnDestroy(): void {
		// Remove the required theme classes.
		this.changeContentLayoutClass('content', 'remove');
		if (this.activeTheme) {
			this.changeThemeClass(this.activeTheme, 'remove');
		}
	}
}
