import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionDirective } from '@app/services/permissions/permission.directive';
import { PermissionContextService } from '@app/services/permissions/permission-context.service';
import { PermissionValidationService } from '@app/services/permissions/permission-validation.service';

@NgModule({
	declarations: [PermissionDirective],
	imports: [CommonModule],
	exports: [PermissionDirective],
	providers: [
		PermissionContextService,
		PermissionValidationService
	]
})
export class PermissionModule { }
