import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ComponentMessage } from '@app/models/component-message.model';

/**
 * Service for messaging between components
 * Example of usage:
 * 1) this._cmService.sendMessage(new ComponentMessage('name-of-target-component', Object));               - Send of data.
 * 2) this._cmService.getMessage('name-of-target-component').subscribe((cm: ComponentMessage) => { ... }); - Data receive.
 *
 * @note Don't forget to unsubscribe the messaging service in a target component.
 * @link https://www.py4u.net/discuss/356615
 *
 * @link https://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject
 * @link https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable
 */
@Injectable({ providedIn: 'root' })
export class ComponentMessagingService implements OnDestroy {

	/** ************************************ Variables *************************************** */

	private subject: Subject<ComponentMessage>;

	/** *********************************** Constructor ************************************** */

	constructor() {
		this.subject = new Subject<ComponentMessage>();
	}

	/** ************************************* Methods **************************************** */

	sendMessage(cm: ComponentMessage) {
		this.subject.next(cm);
	}

	getMessage(name: string): Observable<ComponentMessage> {
		return this.subject.asObservable().pipe( // Filter by the target component name to get rid of duplicate calls.
			filter((cm: ComponentMessage) => cm?.name === name)
		);
	}

	ngOnDestroy() {
		this.subject.next(null);
		this.subject.unsubscribe();
	}
}
