import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '@app/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/**
 * JWT is in local storage as the Authorization header
 * in any HTTP request that is sent
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private readonly _authService: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (! this._authService.loggedIn) {
			return next.handle(req);
		}

		return this._authService.getTokenSilently$().pipe(
			mergeMap((token) => {
				const tokenReq = req.clone({
					setHeaders: { Authorization: `Bearer ${token}` },
				});
				return next.handle(tokenReq);
			}),
			catchError((err) => throwError(() => new Error(err)))
		);
	}
}
