import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page, PageRequest } from '@app/models/page.model';

export abstract class BaseHttpService {

	// eslint-disable-next-line no-magic-numbers
	public readonly PAGE_SIZE = 50;
	protected baseUrl: string;
	protected config = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

	protected constructor(protected http: HttpClient) {}

	public params(pageable?: PageRequest, search?: string): HttpParams {
		if (! pageable) pageable = { page: 0, size: this.PAGE_SIZE, sortParams: [] };

		let params = new HttpParams();
		params = params.append('page', pageable.page.toString());
		params = params.append('size', pageable.size.toString());

		pageable.sortParams.forEach(sortParam => params = params.append('sort', sortParam));

		if (search) {
			params = params.append('search', search);
		}

		return params;
	}

	protected typeCast<T>(
		obs: Observable<T>,
		type: { new(): T; },
		properties: Record<string, { new(): any; }> = {}
	): Observable<T> {
		return obs.pipe(map(obj => this.typeCastObject(obj, type, properties)));
	}

	protected typeCastArray<T>(
		obs: Observable<Array<T>>,
		type: { new(): T; },
		properties: Record<string, { new(): any; }> = {}
	): Observable<Array<T>> {
		return obs.pipe(map(arr => arr.map(obj => this.typeCastObject(obj, type, properties))));
	}

	protected typeCastPage<T>(
		obs: Observable<Page<T>>,
		type: { new(): T; },
		properties: Record<string, { new(): any; }> = {}
	): Observable<Page<T>> {
		return obs.pipe(map(page => {
			page.content = page.content.map(obj => this.typeCastObject(obj, type, properties));
			return page;
		}));
	}

	private typeCastObject<T>(
		obj: T,
		type: { new(): T; },
		properties: Record<string, { new(): any; }> = {}
	): T {
		// eslint-disable-next-line new-cap
		const result = Object.assign(new type(), obj);
		for (const key in properties) {
			if (Object.prototype.hasOwnProperty.call(result, key)) {
				const childType = properties[key];
				if (result[key] instanceof Array) {
					// eslint-disable-next-line new-cap
					result[key] = result[key].map(child => Object.assign(new childType(), child));
				} else {
					// eslint-disable-next-line new-cap
					result[key] = Object.assign(new childType(), result[key]);
				}
			}
		}
		return result;
	}
}
