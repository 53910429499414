import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, CompanyMemberRef } from '@feature/companies/shared/company.model';
import { Page, PageRequest } from '@app/models/page.model';
import { AddUserResponse } from '@feature/users/shared/user.model';
import { BaseHttpService } from '@app/services/base-http.service';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseHttpService {
	/** *********************************** Declarations ************************************* */

	options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

	/** *********************************** Constructor ************************************** */

	constructor(private _http: HttpClient) {
		super(_http);
	}

	/** ************************************* Methods **************************************** */

	public getById(id: any): Observable<Company> {
		id = encodeURIComponent(id);
		return this._http.get<Company>(`${environment.config.apiUrl}/companies/${id}`);
	}

	public getCompanies(search?: string, type? : string, pageRequest?: PageRequest): Observable<Page<Company>> {
		let params = this.params(pageRequest, search);
		if (type) { params = params.append('type', type); }

		const result = this._http.get<Page<Company>>(`${environment.config.apiUrl}/companies`, { params });
		return result.pipe(map(page => {
			page.content = page.content.map(company => Object.assign(new Company(), company));
			return page;
		}));
	}

	public getRefByCompanyIdAndMemberId(companyId: number, memberId: number): Observable<CompanyMemberRef> {
		return this._http.get<CompanyMemberRef>(`${environment.config.apiUrl}/companies/${companyId}/members/${memberId}`);
	}

	public getCompaniesByMemberId(memberId: number): Observable<CompanyMemberRef[]> {
		return this._http.get<CompanyMemberRef[]>(`${environment.config.apiUrl}/users/${memberId}/companies`);
	}

	public getCompanyMembersByCompanyId(companyId: number, pageable?: PageRequest, search?: string): Observable<Page<CompanyMemberRef>> {
		return this._http.get<Page<CompanyMemberRef>>(`${environment.config.apiUrl}/companies/${companyId}/members`, { params: this.params(pageable, search) });
	}

	public createCompany(company: Company): Observable<Company> {
		return this._http.post<Company>(`${environment.config.apiUrl}/companies`, company, this.options);
	}

	/**
	 * Updates a company profile reference
	 * @param {Company} company - A company containing the updated information.
	 * @param {string} [step] - An optional step identifier for tracking the update process.
	 * @returns {Observable<Company>}
	 */
	public updateCompany(company: Company, step?: string): Observable<Company> {
		let profileParams: HttpParams = new HttpParams();
		profileParams = profileParams.append('step', (step != null ? step : null));
		return this._http.put<Company>(`${environment.config.apiUrl}/companies/${company.id}`, company, { params: profileParams });
	}

	public canAddMember(companyId: number, memberEmail: string): Observable<AddUserResponse> {
		memberEmail = encodeURIComponent(memberEmail);
		return this._http.get<AddUserResponse>(`${environment.config.apiUrl}/companies/${companyId}/can-add-member/${memberEmail}`);
	}

	public createMemberRef(companyMemberRef: CompanyMemberRef, createMember?: boolean): Observable<CompanyMemberRef> {
		let requestUrl = `${environment.config.apiUrl}/companies/${companyMemberRef.companyId}/members`;
		if (createMember) {
			requestUrl += '?createMember=true';
		}
		return this._http.post<CompanyMemberRef>(requestUrl, companyMemberRef, this.options);
	}

	public deleteMember(companyId: number, memberId: number): Observable<CompanyMemberRef> {
		return this._http.delete<CompanyMemberRef>(`${environment.config.apiUrl}/companies/${companyId}/members/${memberId}`);
	}

	public resendMembershipRequest(companyId: number, memberId: number): Observable<CompanyMemberRef> {
		return this._http.put<CompanyMemberRef>(`${environment.config.apiUrl}/companies/${companyId}/members/${memberId}`, null, this.options);
	}

	public acceptMember(key: string): Observable<CompanyMemberRef> {
		return this._http.put<CompanyMemberRef>(`${environment.config.apiUrl}/company-member-request/${key}/accept`, null);
	}

	public declineMember(key: string): Observable<CompanyMemberRef> {
		return this._http.put<CompanyMemberRef>(`${environment.config.apiUrl}/company-member-request/${key}/decline`, null);
	}

	public updateMemberRef(ref: CompanyMemberRef): Observable<CompanyMemberRef> {
		return this._http.put<CompanyMemberRef>(`${environment.config.apiUrl}/company-member-request/${ref.id}`, ref);
	}
}
