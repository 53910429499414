import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@app/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private _authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status === 401) {
					// Auto logout if 401 response returned from api
					this._authService.logout();
					location.reload();
				}

				// @link https://stackoverflow.com/a/30469297
				const error = err.error.message || err.statusText;
				return throwError(() => new Error(error));
			})
		);
	}
}
