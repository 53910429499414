import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ObservableInput, from, of, lastValueFrom } from 'rxjs';
import { catchError, concatAll, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { appPermissions, PermissionFunction, PermissionsObject, PermissionTypesObject } from '@app/services/permissions/permission.model';
import { Individual, SignUpStep, User } from '@feature/users/shared/user.model';
import { Company, CompanyMemberRef, CompanyMemberRole, CompanyType } from '@feature/companies/shared/company.model';
import { AppContextService } from '@app/services/app-context.service';
import { AppContext } from '@app/models/app-context.model';
import { isFunction } from 'rxjs/internal/util/isFunction';
import { ProfileStepsService } from '@feature/profiles/shared/profile-steps.service';
import { PermissionValidationService } from '@app/services/permissions/permission-validation.service';

@Injectable({ providedIn: 'root' })
export class PermissionContextService implements OnDestroy {

	/** *********************************** Declarations ************************************* */

	private typesSource: BehaviorSubject<PermissionTypesObject>;
	private _currentContext: User | CompanyMemberRef;
	private _type: string;
	private _role: string;

	/** ********************************* Getters/Setters ************************************ */

	get currentContext(): User | CompanyMemberRef { return this._currentContext; }
	set currentContext(currentContext: User | CompanyMemberRef) {
		this._currentContext = currentContext;
		this.setTypeAndRole();
	}

	/** *********************************** Constructor ************************************** */

	constructor(
		private _context: AppContextService,
		private _profileStepsService: ProfileStepsService
	) {
		this.typesSource = new BehaviorSubject<PermissionTypesObject>({});
		this._context.currentContext
			.pipe(filter((ctx: AppContext) => ctx !== null))
			.subscribe((ctx: AppContext) => {
				this.currentContext = ctx?.isPersonalContext ? ctx?.user : ctx?.companyMembership;

				// Declare the permissions whose check vary depending on the context.
				this.addTypes(appPermissions);
			});
	}

	/** *************************************** Add ****************************************** */

	public addType(type: string, rolesObj: { [role: string]: Array<PermissionsObject> }, skipObservable = false) {
		const types = {
			...this.typesSource.value,
			[type]: rolesObj
		};
		if (! skipObservable) {
			this.typesSource.next(types);
		}
	}

	public addTypes(typesObj: PermissionTypesObject) {
		this.typesSource.next({});
		Object.keys(typesObj).forEach((type) => {
			this.addType(type, typesObj[type], true);
		});
		this.typesSource.next(typesObj);
	}

	/** ************************************** Remove ***************************************** */

	public flushTypes() {
		this.typesSource.next({});
		this.typesSource = null;
	}

	public removeType(roleName: string) {
		const types = {
			...this.typesSource.value
		};
		delete types[roleName];
		this.typesSource.next(types);
	}

	/** *************************************** Get ****************************************** */

	public getTypes() {
		return this.typesSource.value;
	}

	public getType(type: string): {[role: string]: Array<PermissionsObject>} | null {
		return Object.prototype.hasOwnProperty.call(this.typesSource, type) ? this.typesSource.value[type] : null;
	}

	public hasTypes(): boolean {
		return Object.keys(this.typesSource.value).length > 0;
	}

	/** ************************************* Checks **************************************** */

	/**
     * Checks the given permissions to the current user context (individual or business)
     * @param {Array<string | PermissionsObject>} permissions - Permissions array to be checked against the current context
     * @param {string} type - Type to be checked against the PermissionTypesObject or current context (optional)
     * @param {Array<string>} roles - Roles array to be checked against the PermissionTypesObject or current context role (optional)
     * @returns Promise<boolean>
     */
	public contextPermissions(permissions: Array<string | PermissionsObject>, type?: string, roles?: Array<string>): Promise<boolean> {
		// 1) Check the context type (user or company member relationship.)
		if (this.currentContext instanceof CompanyMemberRef) {
			// 2) Check current context type (customer, agent.)
			const companyType: string = type || this.currentContext.companyType;          // E.g. agent
			const memberRoles: Array<string> = roles || [this.currentContext.memberRole]; // E.g. [viewer, editor]
			return this.hasPermissions(companyType, memberRoles, permissions);
		} else if (this.currentContext instanceof User) {
			// 2) Check current context type (customer, talent.)
			const userType: string = type || ((this.currentContext as User)?.roles != null && (this.currentContext as User)?.roles[0]?.alias);
			const memberRoles: Array<string> = [CompanyMemberRole.owner];
			return this.hasPermissions(userType, memberRoles, permissions);
		}
		return Promise.resolve(false);
	}

	/**
     * Permissions check within the user's type and role
     * @param {string} type - Type to be checked against the PermissionTypesObject.
     * @param {Array<string>} roles - Roles array to be checked against the PermissionTypesObject.
     * @param Array<string | PermissionsObject> permissions - Permissions array to be checked against the specific type and role.
	 * @returns Promise<boolean>
     * @private
     */
	private hasPermissions(type: string, roles: Array<string>, permissions: Array<string | PermissionsObject>): Promise<boolean> {
		// @note Type is undefined in case the user is new and hasn't picked profile type yet.
		// if (this.typesSource.value[type] == null) return Promise.resolve(false);
		return lastValueFrom(
			from(roles).pipe( // 1) Walk through all the roles to be searched.
				mergeMap((role: string) => { // @link https://bit.ly/3ByBtYT
					let permissionsAsObjects = (permissions as Array<PermissionsObject>);
					let permissionPromises: Array<Observable<boolean>>;

					// @note Ability to accept PermissionsObject array, rather than being stacked to PermissionTypesObject.
					if (permissionsAsObjects != null) {
						permissionPromises = permissionsAsObjects.map((permissionObject: PermissionsObject, i: number) => {
							// Check allows to accept permissions as a string, e.g. *hasPermissions="['canCreatePayment']".
							if (! (permissionObject instanceof Object)) {
								if (Object.prototype.hasOwnProperty.call(PermissionValidationService, permissionObject)) {
									const validatorFunction = PermissionValidationService[permissionObject];
									return this._validatorFunction(validatorFunction);
								}
								return of(false);
							} else if ((permissionObject instanceof Object) && Object.keys(permissionObject)[0]) {
								const validatorFunction = permissionObject[Object.keys(permissionObject)[0]];
								return this._validatorFunction(validatorFunction);
							}
							// Make sure that it's not null.
							return (permissionObject instanceof Object) ? of(!! permissionObject[Object.keys(permissionObject)[0]]) : of(false);
						});
					} else if (this.typesSource.value[type] != null && this.typesSource.value[type][role] && Array.isArray(this.typesSource.value[type][role])) {
						// 2) Map and collect all the permission Observables.
						permissionsAsObjects = (this.typesSource.value[type][role] as Array<PermissionsObject>);
						permissionPromises = permissions.map((permission: string) => {
							const permissionObject = permissionsAsObjects.find((p: any) => Object.prototype.hasOwnProperty.call(p, permission));
							if (permissionObject) {
								const validatorFunction = permissionObject[permission];
								return this._validatorFunction(validatorFunction);
							}
							return of(!! this.typesSource.value[type][role][permission]);
						});
					}

					if (permissionPromises) {
						// @link https://www.learnrxjs.io/learn-rxjs/operators/creation/from
						return from(permissionPromises).pipe(
							concatAll(), // @link https://bit.ly/3kQAWuW
							map((hasPermission: boolean) => hasPermission !== false),
						);
					}
					return of(null);
				})
			)
		);
	}

	/** ************************************* Methods **************************************** */

	/**
	 * Set the type and role based on the current context
	 */
	private setTypeAndRole(): void {
		// 1) Check the context type (user or company member relationship.)
		if (this.currentContext instanceof CompanyMemberRef) {
			// 2) Check current context type (customer, agent.)
			this._type = (this.currentContext as CompanyMemberRef).companyType;
			this._role = (this.currentContext as CompanyMemberRef).memberRole;
		} else if (this.currentContext instanceof User) {
			// 2) Check current context type (customer, talent.)
			this._type = (this.currentContext as User)?.maxRole?.alias || '';
			this._role = CompanyMemberRole.owner;
		}
	}

	/**
	 * Check if the given value is boolean
	 * @param value
	 * @returns {value is boolean}
	 * @private
	 */
	private isBoolean(value: any): value is boolean {
		return typeof value === 'boolean';
	}

	/**
	 * Check whether the current user context belongs to a customer or to an agent
	 * @returns {boolean}
	 */
	public isCustomerOrAgent(): boolean {
		if (this._type == null) { return false; }
		return (this._type.includes(CompanyType.customer) || this._type.includes(CompanyType.agent));
	}

	/**
	 * Check whether the current user context belongs to a customer
	 * @returns {boolean}
	 */
	public isCustomer(): boolean {
		if (this._type == null) { return false; }
		return this._type.includes(CompanyType.customer);
	}

	/**
	 * Check whether the current user context belongs to an agent
	 * @returns {boolean}
	 */
	public isAgent(): boolean {
		if (this._type == null) { return false; }
		return this._type.includes(CompanyType.agent);
	}

	/**
	 * Check whether the current user context belongs to individual customer
	 * @returns {boolean}
	 */
	public isIndividualCustomer(): boolean {
		return (this.currentContext instanceof User) && (this.currentContext as User)?.isIndividual && (this.currentContext as User).isCustomer;
	}

	/**
	 * Check whether the current context belongs a user, not a company member
	 * @returns {boolean}
	 */
	public isPersonalContext(): boolean { return ! this.isMember(); }

	/**
	 * Check whether the current context belongs a company member
	 * @returns {boolean}
	 */
	public isCompanyContext(): boolean { return this.isMember(); }

	/**
	 * Check whether the current context belongs to an individual customer
	 * @returns {boolean}
	 */
	public isPersonalCustomer(): boolean {
		return (this.currentContext instanceof User) && (this.currentContext as User).isCustomer && this.isPersonalContext();
	}

	/**
	 * Check whether the current context belongs to a customer company member
	 * @returns {boolean}
	 */
	public isCompanyCustomer(): boolean {
		return this.isCompanyContext() && (this.currentContext as CompanyMemberRef).isCustomer;
	}

	/**
	 * Check whether the current context belongs to an international payer
	 * @returns {boolean}
	 */
	public isInternationalCustomer(): boolean {
		if (! this.isPersonalCustomer() && ! this.isCompanyCustomer()) return false;
		const userContext = (this.currentContext instanceof User) ? (this.currentContext as User) : null;
		if (userContext == null) return false;
		return (((this.currentContext instanceof User) // If it's an individual.
			&& (userContext.individual as Individual)?.profile?.address != null // Make sure that address is available (important for user who just picked type).
			&& (userContext.individual as Individual)?.profile?.address?.stateId == null) // If it's not an international individual.
		|| ((this.currentContext instanceof CompanyMemberRef) // If it's a company.
			&& (userContext.company as Company)?.profile?.address != null // Make sure that address is available (important for user who just picked type).
			&& (userContext.company as Company)?.profile?.address?.stateId == null) // If it's not an international company payer.
		);
	}

	/**
	 * Check whether the current context belongs to an enterprise payer
	 * @returns {boolean}
	 */
	public isEnterpriseCustomer(): boolean {
		return this.isCompanyContext() && (this.currentContext as CompanyMemberRef).isCustomer && (this.currentContext as CompanyMemberRef).companyIsEnterprise;
	}

	/**
	 * Check access to the profile step
	 * @param {string} profileStep
	 * @returns {boolean}
	 */
	public hasAccessToProfileStep(profileStep: string): boolean {
		if (profileStep == null) return false;
		const getStepByName = (this._profileStepsService.getStepByName(profileStep) as SignUpStep);
		// If international customer, check if the current step is among the restricted steps.
		const stepIsRestricted = this.isInternationalCustomer()
			? this._profileStepsService.isStepRestrictedForInternationalCustomer(profileStep)
			: false;

		return (this.currentContext.signupStep == null && ! stepIsRestricted)
			|| (getStepByName != null && (
				(getStepByName.isFinished && ! getStepByName.isHidden)
				|| (this.currentContext.signupStep === getStepByName.alias)
			));
	}

	/**
	 * Check whether the current context belongs to a talent
	 * @returns {boolean}
	 */
	public isTalent(): boolean {
		return (this.currentContext instanceof User) && (this.currentContext as User)?.isTalent;
	}

	/**
	 * Check whether the current context belongs to a member
	 * @returns {boolean}
	 */
	public isMember(): boolean {
		return (this.currentContext instanceof CompanyMemberRef);
	}

	/**
	 * Check whether the current context has agents
	 * @returns {boolean}
	 */
	public isClient(): boolean {
		return this.isTalent() && (this.currentContext as User).agents?.length > 0;
	}

	/**
	 * Check whether the current context belongs to a new user
	 * @returns {boolean}
	 */
	public isNew(): boolean {
		return ((this.currentContext instanceof User) && (this.currentContext as User)?.isNew)
		|| ((this.currentContext instanceof CompanyMemberRef) && (this.currentContext as CompanyMemberRef)?.signupStep != null);
	}

	/**
	 * Check whether the current context belongs to a super administrator
	 * @returns {boolean}
	 */
	public isSuperAdmin(): boolean {
		return (this.currentContext instanceof User) && (this.currentContext as User)?.isAdmin;
	}

	/**
	 * Check whether the current context role equals or below the searched role (lower is higher ranked)
	 * @param {string} matchRole - The role to match.
	 * @param {boolean} strict - Whether the match has to be strict.
	 * @returns {boolean}
	 */
	public roleIsEqualOrBelow(matchRole: string, strict?: boolean): boolean {
		const matchRoleIndex: number = Object.keys(CompanyMemberRole).indexOf(matchRole);
		const contextRoleIndex: number = Object.keys(CompanyMemberRole).indexOf(this._role);
		const match: boolean = (strict === true ? contextRoleIndex === matchRoleIndex : contextRoleIndex <= matchRoleIndex);
		return ((matchRoleIndex !== -1 && contextRoleIndex !== -1) && match);
	}

	/**
	 * Check whether the current context belongs to the same user/member
	 * @param {number} memberId - Member ID to check.
	 * @returns {boolean}
	 */
	public isSameUser(memberId: number = null): boolean {
		return ((this.currentContext instanceof User) && (this.currentContext as User)?.id === memberId)
        || ((this.currentContext instanceof CompanyMemberRef) && (this.currentContext as CompanyMemberRef)?.memberId === memberId);
	}

	/**
	 * Run the permission verification function and present the results
	 * @param {PermissionFunction} permissionFunc - The permission function to execute.
	 * @returns {Observable<boolean>}
	 * @private
	 */
	private _validatorFunction(permissionFunc: PermissionFunction): Observable<boolean> {
		// @note Pass 'this' to validatorFunction because it's required in a constructor of PermissionFunction.
		return of(null).pipe( // @link https://www.learnrxjs.io/learn-rxjs/operators/creation/of
			map(() => isFunction(permissionFunc) ? permissionFunc(this) as Promise<boolean> : permissionFunc),
			switchMap((promise: Promise<boolean> | PermissionFunction): ObservableInput<boolean> =>
				this.isBoolean(promise) ? of(promise as boolean) : (promise as Promise<boolean>)
			),
			catchError(() => of(false))
		);
	}

	/** ************************************* Destroy **************************************** */

	ngOnDestroy(): void {
		this.flushTypes();
		if (this._context.currentContext != null) {
			this._context.currentContext.unsubscribe();
		}
	}
}
