import { WidgetTemplate } from '@shared/widgets/shared/widget-template.model';
import { ChartType } from 'chart.js';
import { WidgetArea } from '@shared/widgets/shared/widget-area.model';

/** Widget */
export class Widget {
	id: number;

	sku: string;
	templateLayoutName: string;
	template: WidgetTemplate;
	chart: ChartType;

	rowIndex: number; // Defines the widget's position on the X-axis.
	columnIndex: number; // Defines the widget's position on the Y-axis.
}

export class WidgetAreaRef {
	id?: number;
	areaId: number;
	widgetId: number;
}

/** Widget date period for a universal request */
export enum WidgetDatePeriod {
	oneDay = '1D',
	thirtyDays = '30D',
	sixtyDays = '60D',
	oneWeek = '1W',
	thirtyToSixtyDays = '30-60D',
	oneMonth = '1M',
	threeMonth = '3M',
	sixMonth = '6M',
	oneYear = '1Y',
	fiveYears = '5Y',
	ytd = 'YTD',
	hnrJanDec = 'HNR-JAN-DEC',
	hnrAprMar = 'HNR-APR-MAR',
	hnrJulJun = 'HNR-JUL-JUN',
	hnrOctSep = 'HNR-OCT-SEP'
}

/** Widget dataset request parameters */
export interface WidgetDataParams {
	type: string;
	[key: string]: string | Array<any>;
}

/** Widget dataset request, required for the chart rendering */
export class WidgetDataRequest {
	id: number;
	layoutName: string;
	params: WidgetDataParams;
}

/** Widget dataset response */
export class WidgetDataResponse {
	widgetId: number;
	widgetData: WidgetDataParams | Array<WidgetDataParams>;
}
