import { User } from '@feature/users/shared/user.model';
import { CompanyMemberRef, CompanyMemberRole } from '@feature/companies/shared/company.model';

export class AppContext {

	/** *********************************** Declarations ************************************* */

	user: User;
	companyMembership: CompanyMemberRef;

	private _canEditProfile: boolean;
	private _canCreateProfile: boolean;

	/** ********************************* Getters/Setters ************************************ */

	// If current context points to an individual profile (intentionally == not === to cover undefined and null)
	get isPersonalContext(): boolean { return this.companyMembership == null; }
	// If current context points to a company profile.
	get isCompanyContext(): boolean { return ! this.isPersonalContext; }
	get isAgent(): boolean { return this.isPersonalContext ? this.user.isAgent : (this.isCompanyContext ? this.companyMembership.isAgent : false); }
	get isTalent(): boolean { return this.isPersonalContext && this.user.isTalent; }
	get isCustomer(): boolean { return this.isPersonalContext ? this.user.isCustomer : (this.isCompanyContext ? this.companyMembership.isCustomer : false); }
	get isAdmin(): boolean { return this.user.isAdmin; }
	get isPersonalCustomer(): boolean { return this.isCustomer && this.isPersonalContext; }
	get isCompanyCustomer(): boolean { return this.isCustomer && this.isCompanyContext; }
	get isEnterprise(): boolean { return this.isCompanyContext && this.companyMembership.companyIsEnterprise; }
	get name(): string { return this.isPersonalContext ? 'Personal' : (this.isCompanyContext ? this.companyMembership.companyName : ''); }
	get fullName(): string { return this.isPersonalContext ? this.user?.fullName : (this.isCompanyContext ? this.companyMembership.companyName : ''); }
	get isReady(): boolean { return this.redirectUrlStep === undefined; }
	get roleAlias(): string { return this.isAgent ? 'agent' : (this.isCustomer ? 'customer' : (this.isTalent ? 'talent' : '')); }
	get unknownType(): boolean { return ! this.user.isMigrated && ((this.isCompanyContext && this.companyMembership === undefined) || (this.isPersonalContext && ! this.user.isIndividual)); }
	get redirectUrlStep(): string {
		if (this.isAdmin) return undefined;
		if (this.unknownType) return 'type';

		return this.isPersonalContext && this.user?.signupStep !== undefined
			? this.user?.signupStep
			: (this.isCompanyContext && this.companyMembership?.signupStep !== undefined
				? this.companyMembership.signupStep
				: undefined
			);
	}
	get redirectUrl(): string {
		if (this.unknownType) return '/profile/type';
		return `/profile/${this.redirectUrlStep || 'details'}`;
	}
	get canEditProfile(): boolean {
		if (
			( // 1) Is a personal user profile and user started the sign-up process or finished it.
				this.isPersonalContext
                && (this.user?.signupStep !== 'type' || this.user?.signupStep === undefined)
			) || ( // 2) Is company member and has rights to edit the company's profile (e.g. owner.)
				this.isCompanyContext
                && this.hasMemberRoles([CompanyMemberRole.owner]) // Roles can expand if required.
			)
		) {
			this._canEditProfile = true;
		}

		return this._canEditProfile;
	}
	get canCreateProfile(): boolean {
		if (
			// 1) Is a personal user profile.
			(this.isPersonalContext && ! this.user.isCompany && this.user?.signupStep === 'type')
			// 2) Is company member.
			|| (this.isCompanyContext && ! this.user.isIndividual && this.hasMemberRoles([CompanyMemberRole.owner]))
			// 3) Is a context (e.g. member) without personal profile type selected.
			|| (this.user?.signupStep == null && this.user?.roles == null)
		) {
			this._canCreateProfile = true;
		} else {
			this._canCreateProfile = false;
		}

		return this._canCreateProfile;
	}

	/** *********************************** Constructor ************************************** */

	constructor(
        private _user?: User,
        private _companyMembership?: CompanyMemberRef
	) {
		this.user = _user;
		this.companyMembership = _companyMembership;
		this._canCreateProfile = false;
		this._canEditProfile = false;
	}

	/** ************************************* Methods **************************************** */

	public hasMemberRoles(memberRoles: Array<string>): boolean {
		return this.companyMembership?.memberRole && memberRoles.includes(this.companyMembership.memberRole.toString());
	}
}
