import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { User } from '@feature/users/shared/user.model';
import { UserService } from '@feature/users/shared/user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
	/** *********************************** Declarations ************************************* */

	// Angular 9+ application with Auth0 keeps on redirecting in handleRedirectCallback after successful login.
	// @link https://community.auth0.com/t/angular-9-application-with-auth0-keeps-on-redirecting-after-successful-login-in-firefox/41394/8
	// @link https://community.auth0.com/t/angular-10-spa-error-on-redirect-after-login-there-are-no-query-params-available-for-parsing/57805/5
	private urlFromAuth: string;

	// Create an observable of Auth0 instance of client
	private auth0Client$ = (
		from(
			createAuth0Client({
				domain: environment.auth0.domain,
				client_id: environment.auth0.clientId,
				redirect_uri: `${environment.auth0.clientUrl}/logged-in`,
				audience: environment.auth0.audience,
				useRefreshTokens: true,
				cacheLocation: 'localstorage'
			})
		) as Observable<Auth0Client>
	).pipe(
		shareReplay(1), // Every subscription receives the same shared value
		catchError((err: any) => throwError(() => new Error(err)))
	);

	// Define observables for SDK methods that return promises by default
	// For each Auth0 SDK method, first ensure the client instance is ready
	// concatMap: Using the client instance, call SDK method; SDK returns a promise
	// from: Convert that resulting promise into an observable
	public isAuthenticated$: Observable<boolean | Auth0Client> = this.auth0Client$.pipe(
		concatMap((client: Auth0Client) => from(client.isAuthenticated())),
		// eslint-disable-next-line no-return-assign
		tap((res: any) => (this._loggedIn = res))
	);

	public handleRedirectCallback$: Observable<RedirectLoginResult> = this.auth0Client$.pipe(
		concatMap((client: Auth0Client) => from(client.handleRedirectCallback(this.urlFromAuth)))
	);

	// Create subject and public observable of Auth0 user data.
	private _auth0UserSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public auth0User$ = this._auth0UserSubject$.asObservable();

	// Server API profile.
	private _apiUserSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	private _apiUser$: Observable<User> = this._apiUserSubject$.asObservable();

	// Create a local property for login status.
	private _loggedIn: boolean | null;
	private _finishedChecking: boolean;

	/** ********************************* Getters/Setters ************************************ */

	/**
	 * Check if the user is logged in
	 * @returns {boolean | null} TRUE if confirmed logged in, FALSE if confirmed not logged in, NULL if user is unknown yet
	 */
	public get loggedIn(): boolean | null {
		return this._finishedChecking ? this._loggedIn : null;
	}
	public get apiUser$(): Observable<User> {
		return this._apiUser$;
	}
	/** Get user who is currently logged-in into system */
	public get apiUser(): User | null {
		return this._apiUserSubject$.value ? Object.assign(new User(), this._apiUserSubject$.value) : null;
	}
	/** Set currently logged-in user */
	public set apiUser(user: User | null) {
		localStorage.setItem('currentUser', JSON.stringify(user));
		this._apiUserSubject$.next(Object.assign(new User(), user));
	}

	/** *********************************** Constructor ************************************** */

	constructor(
		private _router: Router,
		private _userService: UserService
	) {
		this._loggedIn = null;
		this._finishedChecking = false;

		// On initial load, check authentication state with authorization server.
		// Set up local auth streams if user is already authenticated.
		this.localAuthSetup();

		// Handle redirect from Auth0 login
		this.handleAuthCallback();
	}

	/** ************************************* Methods **************************************** */

	// When calling, options can be passed if desired:
	// https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
	public getUser$(options?: any): Observable<any> {
		return this.auth0Client$.pipe(
			concatMap((client: Auth0Client) => from(client.getUser(options))),
			tap((user: User) => this._auth0UserSubject$.next(user))
		);
	}

	/**
	 * Get Auth0 Token silently
	 * When calling, options can be passed if desired
	 * @link https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
	 */
	public getTokenSilently$(options?: any): Observable<any> {
		return this.auth0Client$.pipe(
			concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
		);
	}

	/**
	 * Check user authentication status, or set it up
	 * @note This should only be called on app initialization.
	 */
	private localAuthSetup() {
		// Set up local authentication streams.
		const checkAuth$ = this.isAuthenticated$.pipe(
			concatMap((loggedIn: boolean) => {
				if (loggedIn) {
					this._finishedChecking = true;

					// If authenticated, get user and set in app.
					// @note You could pass options here if needed.
					this._apiUserSubject$.next(Object.assign(new User(), JSON.parse(localStorage.getItem('currentUser'))));
					return this.getUser$();
				}

				const params = window.location.search;
				if (! params.includes('code=') || ! params.includes('state=')) {
					// Allow handleAuthCallback() to set this.
					this._finishedChecking = true;
				}

				// If not authenticated, return stream that emits 'false'.
				return of(loggedIn);
			})
		);
		checkAuth$.subscribe();
	}

	/** Handle Auth0 callback */
	private handleAuthCallback() {
		this.urlFromAuth = window.location.href;
		const params = window.location.search;
		if (params.includes('code=') && params.includes('state=')) {
			let targetRoute: string; // Path to redirect to after login processed
			const authComplete$ = this.handleRedirectCallback$.pipe(
				// Have client, now call method to handle auth callback redirect.
				tap((cbRes: any) => {
					// Get and set target redirect route from callback results.
					if (cbRes.appState) {
						targetRoute = cbRes.appState.target ? cbRes.appState.target : '/dashboard';
					}
				}),
				concatMap(() => {
					// Redirect callback complete; get user and login status.
					return combineLatest([this.getUser$(), this.isAuthenticated$]);
				})
			);

			// Subscribe to authentication completion observable.
			// Response will be an array of user and login status.
			authComplete$.subscribe(([user, loggedIn]) => {
				if (loggedIn) {
					this._finishedChecking = true;

					// Search for the user in the API by Auth0 id.
					this._userService.getById(user.sub).subscribe((user: User) => {
						// eslint-disable-next-line no-undefined
						// if (user != null) {
						// Record the user received from the API request
						this.apiUser = user;

						// @note The non-verified user check was meant to fix the client representation request.
						// What happened, is it interrupted the process for the member request. Requires testing.
						// Double check if the non-verified user has to be redirected to the verification step.
						// targetRoute = (this.apiUser?.maxRole?.alias == null ? '/profile/verification' : targetRoute);

						// Redirect to target route after callback processing.
						this._router.navigateByUrl(targetRoute);
						// }
					});
				}
			});
		} else if (params.includes('error=')) {
			this.logout();
		}
	}

	/** Auth0 login screen */
	public login(redirectPath = '/orders') {
		this.openAuth0(redirectPath);
	}

	/** Auth0 sign-up screen */
	public signup(userRole?: string, loginHint?: string) {
		// If/when we switch from 'Auth0 Classic Universal Login Experience' to 'Auth0 New Universal Login Experience' - change from 'signUp' to 'signup'.
		// @note screenHint parameter choose the Auth0 screen (case-sensitive), loginHint parameter pre-fill the email field, or pick the appropriate session before logging in.
		this.openAuth0('/orders', 'signUp', userRole, loginHint);
	}

	/** Auth0 logout */
	public logout() {
		// Ensure Auth0 client instance exists
		this.auth0Client$.subscribe((client: Auth0Client) => {
			// Call method to log out.
			client.logout({
				client_id: environment.auth0.clientId,
				returnTo: environment.auth0.landingPageUrl,
			});
			localStorage.removeItem('currentUser');
		});
	}

	/** Perform authorization using Auth0 methods */
	private openAuth0(redirectPath: string, screenHint?: string, userRole?: string, loginHint?: string) {
		// Clear localStorage in case it was cached by session timeout.
		localStorage.clear();

		// A desired redirect path can be passed to the login method (e.g., from a route guard).
		// Ensure Auth0 client instance exists.
		this.auth0Client$.subscribe((client: Auth0Client) => {
			const message = redirectPath.includes('message=') ? new URLSearchParams(redirectPath).get('message') : '';
			// Call method to log in
			client.loginWithRedirect({
				redirect_uri: `${environment.auth0.clientUrl}/logged-in`,
				appState: {
					target: redirectPath
				},
				screen_hint: screenHint,
				login_hint: loginHint, // https://github.com/auth0/auth0-spa-js/issues/440
				message
			});
		});
	}

	/** Trigger Auth0 reset password */
	public sendResetPasswordEmail() {
		let userEmail = '';
		this.auth0User$.subscribe((auth0User: any) => {
			userEmail = auth0User.email;
		});

		this._userService.resetPassword(userEmail);
	}
}
