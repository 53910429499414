import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EnsureModuleLoadedOnceGuard } from '@app/helpers/module-import.guard';
import { DynamicElementsContainerModule } from '@app/components/dynamic-elements-container/dynamic-elements-container.module';
import { PermissionModule } from '@app/services/permissions/permission.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DynamicElementsContainerModule, // Shall contain dynamic elements as tooltip, popup etc.
		PermissionModule,
	],
	exports: [],
	providers: [],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		super(parentModule);
	}
}
