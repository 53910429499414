import { ChangeDetectionStrategy, Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DynamicElementsContainerDirective } from './dynamic-elements-container.directive';
import { DynamicElementsContainerService } from './dynamic-elements-container.service';

@Component({
	selector: 'app-dynamic-elements-container',
	exportAs: 'appDynamicElementsComponent',
	changeDetection: ChangeDetectionStrategy.OnPush, // Automatic change detection is deactivated until reactivated.
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="app-dynamic-elements-container">
			<ng-template appDynamicElementsContainer></ng-template>
		</div>
	`,
	styles: [`
		/* Host (target) element. */
		.host:not(.label):not(button) {
			/*position: unset;*/
			position: relative;
			border-bottom: 1px dotted;
			mix-blend-mode: normal;
			display: inline-block;
			cursor: help;
		}
	`]
})
export class DynamicElementsContainerComponent implements OnInit {

	@ViewChild(DynamicElementsContainerDirective, { static: true }) dynamicElementsContainer: DynamicElementsContainerDirective;

	constructor(
		private renderer: Renderer2,
		private dynamicElementsContainerService: DynamicElementsContainerService,
		private router: Router
	) {
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) { this.dynamicElementsContainerService.destroy(); }
		});
	}

	ngOnInit() {
		const viewContainerRef = this.dynamicElementsContainer.viewContainerRef;
		this.dynamicElementsContainerService.dynamicElementsContainer = viewContainerRef; // Remember the ViewContainer reference.
		this.dynamicElementsContainerService.renderer = this.renderer;    // Remember the Renderer2 for the further access.
	}
}
