import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AppContext } from '@app/models/app-context.model';
import { UserService } from '@feature/users/shared/user.service';
import { CompanyService } from '@feature/companies/shared/company.service';
import { User } from '@feature/users/shared/user.model';
import { AuthService } from '@app/services/auth.service';
import { CompanyMemberRef, CompanyMemberRequestStatus } from '@feature/companies/shared/company.model';
import { SnackBarService } from '@app/services/snackbar.service';
import { ComponentMessagingService } from '@app/services/component-messaging.service';
import { UtilsService } from '@app/services/utils.service';

@Injectable({ providedIn: 'root' })
export class AppContextService {

	public currentContext: BehaviorSubject<AppContext> = new BehaviorSubject<AppContext>(null);
	// If the user is being change from here, no need to subscribe and listen to the change.
	private localUserChange = false;

	constructor(
        private _router: Router,
		private _route: ActivatedRoute,
		private _utilsService: UtilsService,
		private _snackbarService: SnackBarService,
        private _authService: AuthService,
        private _userService: UserService,
        private _companyService: CompanyService,
		private _cmService: ComponentMessagingService,
	) {
		this._authService.apiUser$
			.pipe(filter(user => user !== null && ! this.localUserChange))
			.subscribe((user: User) => {
				const ctxStr = localStorage.getItem('currentContext');
				if (ctxStr !== null) { // If context found, use it.
					const ctx: AppContext = Object.assign(new AppContext(), JSON.parse(ctxStr));
					ctx.user = user;
					if (ctx.companyMembership) {
						// ETT-230 Fetch the up-to-date ref.
						this._companyService.getRefByCompanyIdAndMemberId(ctx.companyMembership.companyId, ctx.companyMembership.memberId).subscribe({
							next: (ref: CompanyMemberRef) => {
								ctx.companyMembership = Object.assign(new CompanyMemberRef(), ref);
								this.currentContext.next(ctx);
							},
							error: (error: any) => {
								if (error.status === 403) {
									this.setInitialContext(user, ctx.companyMembership);
								} else {
									this.setInitialContext(user);
								}
							}
						});
					} else {
						this.currentContext.next(ctx);
					}
				} else { // If no context set, yet, search for it.
					this.setInitialContext(user);
				}
			});
	}

	private setInitialContext(user: User, notAllowed?: CompanyMemberRef) {
		if (user?.id == null) return;
		this._companyService.getCompaniesByMemberId(user.id)
			.pipe(filter((cmr: Array<CompanyMemberRef>) => cmr != null))
			.subscribe((companiesMembership: Array<CompanyMemberRef>) => {
				const accepted: Array<CompanyMemberRef> = companiesMembership.filter((ref: CompanyMemberRef) => ref.status === CompanyMemberRequestStatus.accepted);
				let newCtxName: string = '';
				if (accepted?.length > 0) { // Use first company as default if not already set.
					const memberRef: CompanyMemberRef = Object.assign(new CompanyMemberRef(), accepted[0]);
					this.setDefaultCompanyContext(memberRef);
					newCtxName = memberRef.companyName;
				} else {
					this.setPersonalContext(true);
					newCtxName = 'Personal';
				}
				if (notAllowed) {
					this._snackbarService.warn(`You are not allowed to use the "${notAllowed.companyName}" context due to permissions. Switching to "${newCtxName}" instead.`);
				}
			});
	}

	public setCompanyContext(company: CompanyMemberRef, skipReload = false) {
		this.setContext(company, skipReload);
	}

	public setDefaultCompanyContext(company: CompanyMemberRef) {
		if (! this.currentContext.value) {
			this.setCompanyContext(company, true);
		}
	}

	public setPersonalContext(skipReload = false) {
		this.setContext(undefined, skipReload);
	}

	public refreshContext(): Promise<boolean> {
		const ctx = this.currentContext.value;
		const promise = new Promise<boolean>((resolve, reject) => {
			if (ctx) {
				// Update User either way - this is to get User.isCompany correct.
				const sub: Subscription = this._userService.getById(ctx.user.id.toString())
					.subscribe({
						next: (updated: User) => {
							this.localUserChange = true;
							this._authService.apiUser = Object.assign(new User(), updated);
							this.localUserChange = false;
							if (ctx.isPersonalContext) {
								this.setPersonalContext(); // E.g. after the profile edits, personal context has to be refreshed.
								resolve(true);
							}
							sub.unsubscribe();
						},
						error: (error: any) => reject(error.message)
					});

				if (ctx.isCompanyContext) {
					// @note Capture that user belongs to a company, or checks within platform won't work.
					this._authService.apiUser.isCompany = true;
					const companySub: Subscription = this._companyService.getRefByCompanyIdAndMemberId(ctx.companyMembership.companyId, ctx.user.id)
						.subscribe({
							next: (updated: CompanyMemberRef) => {
								updated = Object.assign(new CompanyMemberRef(), updated);
								this.setCompanyContext(updated);
								resolve(true);
								companySub.unsubscribe();
							},
							error: (error: any) => reject(error.message)
						});
				}
			}
		});

		return promise;
	}

	reload() {
		const newUrl = this._utilsService.getCurrentUrlWithoutQueryParamsVanillaJS(this._router.url);
		const newQueryParams: Params = {};
		this._route.queryParamMap.forEach((params: ParamMap) => {
			params.keys.forEach((paramKey: string) => {
				newQueryParams[paramKey] = null;
			});
		});

		// When the context changes, we might need to re-evaluate the Guards like SignupGuard.
		// https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
		this._router.navigate([newUrl], {
			queryParams: newQueryParams,
			queryParamsHandling: 'merge'
		});
	}

	private setContext(company?: CompanyMemberRef, skipReload = false) {
		this._authService.apiUser$
			.pipe(first((user: User) => user !== null))
			.subscribe((user: User) => {
				// @note Capture that user belongs to a company, or checks within platform won't work.
				if (company) user.isCompany = true;
				const ctx = new AppContext(user, company);

				if (! skipReload) {
					this.reload(); // Try to reload first - line below allows other subscribers to change the URL afterward.
				}

				localStorage.setItem('currentContext', JSON.stringify(ctx));
				this.currentContext.next(ctx);
			});
	}
}
