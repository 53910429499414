import { Injectable, OnDestroy } from '@angular/core';
import { AppContextService } from '@app/services/app-context.service';
import { Individual, SignUpStep, User } from '@feature/users/shared/user.model';
import { Company } from '@feature/companies/shared/company.model';
import { AppContext } from '@app/models/app-context.model';
import { CompanyService } from '@feature/companies/shared/company.service';
import { BaseHttpComponent } from '@app/components/base-http.component';
import { filter, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Injectable({ providedIn: 'root' })
export class ProfileStepsService extends BaseHttpComponent implements OnDestroy {

	// ETT-115 Newly added `Account type` step is required and can't be skipped.
	// By default, steps are not finished and links are not active.
	// @see {@link https://stackblitz.com/edit/angular6-keyvaluepipe-demo?file=src%2Fapp%2Fapp.component.ts}
	steps: { [index: number]: SignUpStep };
	nextStep: string;
	currentStep: string;
	typeStepIndex: number;
	preferencesStepIndex: number;
	taxationStepIndex: number;
	stepsRestrictedToInternationalCustomer: { [index: number]: SignUpStep };

	company: Company;
	individual: Individual;

	/** *********************************** Constructor ************************************** */

	constructor(
		public router: Router,
		public contextService: AppContextService,
		public authService: AuthService,
		protected _companyService: CompanyService,
	) {
		super();

		// Check if the current context belongs to a company, then update details for the context.
		this.safeSubscription(
			this.contextService.currentContext
				.pipe(filter((ctx: AppContext) => ctx !== null && ctx?.isCompanyContext))
				.subscribe((ctx: AppContext) => {
					this.safeSubscription(
						this._companyService.getById(ctx.companyMembership.companyId).subscribe((company: Company) => {
							if (this.contextService.currentContext != null) this.contextService.currentContext.value.user.company = company;
							// ETT-541 Require all user types to visit the profile taxation step and upload a document.
							// ETT-377 Hide the profile taxation step for the international payer.
							// this.checkTaxationStep();
						})
					);
				})
		);

		this.typeStepIndex = 2;
		this.taxationStepIndex = 4;
		this.preferencesStepIndex = 6;
		this.currentStep = null;
		this.checkCurrentStep();

		// List of steps required for international customer check.
		this.stepsRestrictedToInternationalCustomer = {};
		// ETT-541 Require all user types to visit the profile taxation step and upload a document.
		// this.stepsRestrictedToInternationalCustomer[1] = this.getStepByName('taxation') as SignUpStep;
	}

	/** ************************************* Methods **************************************** */

	/**
	 * Check if the current context is available for a user context or member
	 * @returns {boolean}
	 */
	public hasProfile(): boolean {
		const ctx: AppContext = this.contextService.currentContext?.value;
		const hasCompanyOrIndividual: boolean = this.company != null || this.individual != null;
		const hasCtxUserOrMembership: boolean = ctx?.user?.individual != null || ctx?.companyMembership != null;
		return (hasCompanyOrIndividual || (ctx != null && hasCtxUserOrMembership));
	}

	/**
	 * Check that the step alias is an international customer restricted step
	 * @param {string} alias - The step's alias.
	 * @returns {boolean}
	 */
	public isStepRestrictedForInternationalCustomer(alias: string): boolean {
		if (alias == null) return false;
		return Object.values(this.stepsRestrictedToInternationalCustomer)
			.some((step: SignUpStep) => step.alias === alias);
	}

	/**
	 * (Re-)initialise the sign-up steps
	 */
	public initSteps(): void {
		const type: string = this.individual ? 'User' : 'Company';
		this.steps = {
			1: { alias: 'verification', stepName: 'Verification', routerLink: '/profile/verification', isActive: false, isFinished: false, isDisabled: false, isHidden: false },
			2: { alias: 'type', stepName: 'Type', routerLink: '/profile/type', isActive: false, isFinished: false, isDisabled: false, isHidden: false },
			3: { alias: 'details', stepName: `${type} info`, routerLink: '/profile/details', isActive: false, isFinished: false, isDisabled: false, isHidden: false },
			4: { alias: 'taxation', stepName: 'Personal Settings', routerLink: '/profile/taxation', isActive: false, isFinished: false, isDisabled: false, isHidden: false },
			5: { alias: 'banking', stepName: 'Financial Settings', routerLink: '/profile/banking', isActive: false, isFinished: false, isDisabled: false, isHidden: false },
			6: { alias: 'preferences', stepName: 'Preferences', routerLink: '/profile/preferences', isActive: false, isFinished: false, isDisabled: false, isHidden: false }
		};
	}

	/**
	 * Get the step by the alias name
	 * @param {string} alias - The step's alias.
	 * @param {boolean} returnIndexOnly - Specify if the found step's index should be returned.
	 * @returns {number | SignUpStep | null}
	 */
	public getStepByName(alias: string, returnIndexOnly: boolean = false): number | SignUpStep | null {
		const foundIndex: string = Object.keys(this.steps).find((index: string) => this.steps[index].alias === alias);

		if (isNaN(Number(foundIndex))) return null;
		return returnIndexOnly ? Number(foundIndex) : this.steps[foundIndex];
	}

	/**
	 * Check the current step and hide/show steps based on the user's context
	 */
	public checkCurrentStep(): void {
		// Reset sign up steps, because the check method can be called multiple times.
		this.initSteps();
		const ctx = this.contextService.currentContext?.value;
		if (ctx == null) {
			Object.keys(this.steps).map((index: string) => {
				if (Number(index) > 2) this.hideStepsAfterType(index);
			});
			return;
		}

		// Get the current step index.
		const currentContextStepIndex: number = this.getStepByName(ctx?.user?.signupStep, true) as number; // Get the current context step index.
		const currentStepIndex: number = this.getStepByName(this.currentStep, true) as number; // Get the current step index.
		const nextStepIndex: number = this.getStepByName(this.nextStep, true) as number; // Get the next step index.
		const verificationStepIndex: number = this.getStepByName('verification', true) as number; // Get the verification step index.
		const typeStepIndex: number = this.getStepByName('type', true) as number; // Get the type step index.

		const checkFirstSteps = (index) => {
			if (currentStepIndex == null) return;

			// If there is a profile, hide the Verification and Type steps.
			if (Number(index) <= 2) {
				// ETT-137 Gray out the verification step if the user has finished it.
				if (verificationStepIndex < currentStepIndex) {
					this.steps[verificationStepIndex].isFinished = false; // The step is finished but has to be grayed out.
				}
				if (this.hasProfile()) {
					if (this.steps[verificationStepIndex] !== undefined) {
						this.steps[verificationStepIndex].isHidden = true;
						this.steps[verificationStepIndex].routerLink = this.steps[currentStepIndex].routerLink;
					}
					if (this.steps[typeStepIndex] !== undefined) {
						this.steps[typeStepIndex].isHidden = true;
						this.steps[typeStepIndex].routerLink = this.steps[currentStepIndex].routerLink;
					}
				}
			}
		};

		if (! this.hasProfile()) { // We are still within the signup.
			// Get the next step index.
			const nextStepIndex: number = this.getStepByName(this.nextStep, true) as number;
			Object.keys(this.steps).map((index: string) => {
				// If there is a company profile, hide the Verification and Type steps.
				checkFirstSteps(index);

				// The index is the step level from 1 (verification) to 6 (preferences).
				// So we know which step has already been completed.
				if (this.currentStep === this.steps[index].alias) { // Check current step with all the steps.
					this.steps[index].isFinished = true; // The step is finished.
					this.steps[index].isActive = true; // And link is active.
				}

				if (Number(index) <= currentStepIndex // Check if index equals or less than the current step.
				|| (currentStepIndex <= this.typeStepIndex && Number(index) < nextStepIndex) // Check to restrict Profile step from being displayed on the primary steps.
				|| (currentStepIndex > this.typeStepIndex && Number(index) <= nextStepIndex) // Check if index equals the next step.
				|| (Number(index) > this.typeStepIndex && (currentContextStepIndex > this.typeStepIndex && Number(index) <= currentContextStepIndex)) // Match all steps before the current context sign up step.
				) {
					this.steps[index].isFinished = true; // The step is finished.
				}

				// ETT-137 Check if the remaining steps have to be hidden if there is no individual yet.
				this.hideStepsAfterType(index);
			});
		} else { // Signup has been finished.
			Object.keys(this.steps).map((index: string) => {
				// If there is an individual profile, hide the Verification and Type steps.
				checkFirstSteps(index);

				if ((Number(index) > this.typeStepIndex && currentStepIndex > this.typeStepIndex && nextStepIndex == null) // Check if the sign-up finished (next step is empty.)
				|| (Number(index) > this.typeStepIndex && currentStepIndex > this.typeStepIndex && Number(index) <= nextStepIndex) // Check if index equals the next step.
				|| (Number(index) > this.typeStepIndex && (currentContextStepIndex > this.typeStepIndex && Number(index) <= currentContextStepIndex)) // Match all steps before the current context sign up step.
				) {
					this.steps[index].isHidden = false; // The step has to be visible after previous checks.
					this.steps[index].isFinished = true; // The step is finished.
				}

				if (Number(index) === currentStepIndex) {
					this.steps[index].isActive = true; // Set link as active.
				}

				if (Number(index) === this.preferencesStepIndex // If this is preferences step.
					&& (! ctx               // Hide preferences if there's no context.
						|| ! ctx.isReady    // Hide preferences on signup.
						|| (! ctx.isAgent   // Hide preferences if current context is not an agent
						&& ! ctx.isTalent)  // And is not a talent.
					)
				) {
					this.steps[index].isHidden = true;
				}
			});
		}

		// ETT-541 Require all user types to visit the profile taxation step and upload a document.
		// ETT-377 Hide the profile taxation step for the international payer.
		// this.checkTaxationStep();
	}

	/**
	 * Hide the profile taxation step for the international payer
	 */
	public checkTaxationStep(): void {
		const ctx: AppContext = this.contextService.currentContext.value;
		this.steps[this.taxationStepIndex].isHidden = ! this.hasProfile()
			|| (((ctx.user?.individual != null && ctx.companyMembership == null)  // If it's an individual.
				&& ((ctx.user.individual as Individual)?.profile?.address != null // Make sure that address is available (important for user who just picked type).
				&& (ctx.user.individual as Individual)?.profile?.address?.stateId == null)) // If it's not an international individual.
				|| ((ctx.user?.individual == null && ctx.companyMembership != null) // If it's a company.
					&& ((ctx.user.company as Company)?.profile?.address != null // Make sure that address is available (important for user who just picked type).
					&& (ctx.user.company as Company)?.profile?.address?.stateId == null))); // Taxation link is available.
	}

	/**
	 * Hide the remaining steps after the type step
	 * @param {number | string} index - The step index.
	 */
	public hideStepsAfterType(index: number | string): void {
		if (Number(index) > this.typeStepIndex) this.steps[index].isHidden = true;
	}

	/**
	 * Page to be loaded after successfully saving the form
	 * @param {boolean} isProfileCompletion
	 * @param {() => void} callback
	 */
	public nextScreen(isProfileCompletion: boolean = false, callback?: () => void): void {
		const ctx: AppContext = this.contextService.currentContext.value;
		if (! ctx.isReady) {
			this.router.navigateByUrl(ctx.redirectUrl);
			if (callback != null) callback();
		} else if (isProfileCompletion) {
			const redirectUrl: string = this.individual ? '/dashboard' : '/company-details/team';
			const opts = { ignoreCache: true };
			// Fetch new Access Token from Auth0 - this is to have the user roles and permissions in the local Access Token.
			// @note This is probably no longer needed since we don't use the Auth0 permissions anymore. Keeping it for now.
			this.authService.getTokenSilently$(opts)
				.pipe(finalize(() => {
					this.router.navigate([redirectUrl]);
					// @note Fix the issue with sidenav menu refresh after the profile completion.
					setTimeout(() => window.location.reload());
				}))
				.subscribe({
					next: (token: string) => { if (callback != null) { callback(); } },
					error: (error) => console.log(error)
				});
		} else {
			// Do nothing, stay on same screen.
			// eslint-disable-next-line no-lonely-if
			if (callback != null) callback();
		}
	}

	/** ************************************* Destroy **************************************** */

	ngOnDestroy() {
		return super.ngOnDestroy();
	}
}
