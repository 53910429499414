import { Injectable } from '@angular/core';
import { PermissionContextService } from '@app/services/permissions/permission-context.service';
import { User } from '@feature/users/shared/user.model';
import { CompanyMemberRef, CompanyMemberRole } from '@feature/companies/shared/company.model';

/**
 * The permission validation Singleton class that defines the `getInstance` method
 * that lets clients access the unique singleton instance.
 *
 * IMPORTANT: Note that the order of CompanyMemberRole roles has to be kept in a specific order.
 * Less valuable roles has to be added to the end of the list. When they are matched, actually their indexes are.
 *
 * @example
 * PermissionValidationService.canCreatePayment(this.permissionContextService).then((hasPermission: boolean) => hasPermission);
 *
 * @link https://refactoring.guru/design-patterns/singleton/typescript/example
 */
@Injectable({ providedIn: 'root' })
export class PermissionValidationService {

	/** *********************************** Validators *************************************** */

	/** Check if the current context belongs to a super administrator */
	static isSuperAdmin(context: PermissionContextService): Promise<boolean> {
		return Promise.resolve(context.isSuperAdmin());
	}

	/** Check if the current context doesn't belong to a super administrator */
	static isNotSuperAdmin(context: PermissionContextService): Promise<boolean> {
		return Promise.resolve(! context.isSuperAdmin());
	}

	/** Check if the current context belongs to a new user */
	static isNew(context: PermissionContextService): Promise<boolean> {
		return Promise.resolve(context.isNew());
	}

	/** Check if the current context has the profile type selected */
	static hasType(context: PermissionContextService): Promise<boolean> {
		if (context.currentContext instanceof CompanyMemberRef) return Promise.resolve((context.currentContext as CompanyMemberRef)?.companyType != null);
		if (context.currentContext instanceof User) return Promise.resolve((context.currentContext as User)?.roles != null && (context.currentContext as User)?.roles[0]?.alias != null);
		return Promise.resolve(false);
	}

	/** Check if the current context has no profile type selected */
	static hasNoType(context: PermissionContextService): Promise<boolean> {
		if (context.currentContext instanceof CompanyMemberRef) return Promise.resolve((context.currentContext as CompanyMemberRef)?.companyType == null);
		if (context.currentContext instanceof User) return Promise.resolve((context.currentContext as User)?.roles == null || ((context.currentContext as User)?.roles != null && (context.currentContext as User)?.roles[0]?.alias == null));
		return Promise.resolve(true);
	}

	static hasAgents(context: PermissionContextService): Promise<boolean> {
		if (context.hasTypes()) {
			if (context.isTalent() && (context.currentContext as User)?.agents != null) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	/** Check if the current context is agent, or a client with agents */
	static isAgentOrHasAgents(context: PermissionContextService): Promise<boolean> {
		if (context.hasTypes()) {
			if ((context.isAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.owner, true))
			|| (context.isTalent() && (context.currentContext as User)?.agents != null)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canViewPayment(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return Promise.resolve(true);
		if (context.isNew()) return this.isNew(context);
		if (context.hasTypes()) {
			if (context.isTalent()
			|| (context.isCustomerOrAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.viewer))) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canCreatePayment(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return Promise.resolve(false);
		if (context.isNew()) return this.isNew(context);
		if (context.hasTypes()) { // Payer can be or a company, or an individual payer.
			if (context.isCustomer() && (
				(! context.isMember() && ((context.currentContext instanceof User) && (context.currentContext as User)?.isCustomer))
                || (context.isMember() && context.roleIsEqualOrBelow(CompanyMemberRole.editor))
			)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canImportPayment(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return Promise.resolve(false);
		if (context.isNew()) return this.isNew(context);
		if (context.hasTypes()) { // Payer can be or a company, or an individual payer.
			// @note ETT-516 Currently, the ability to import payments is limited to members of the enterprise company.
			if (context.isEnterpriseCustomer() && (context.isMember() && context.roleIsEqualOrBelow(CompanyMemberRole.editor))) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canViewClients(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return Promise.resolve(false);
		if (context.hasTypes()) {
			if (context.isAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.viewer)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canAddEditDeleteClient(context: PermissionContextService): Promise<boolean> {
		if (context.hasTypes()) {
			if (context.isAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.editor)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canViewContributors(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return Promise.resolve(false);
		if (context.hasTypes()) {
			if ((context.isAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.viewer))
			|| context.isClient()) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canUploadDocsToClient(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return this.isSuperAdmin(context);
		if (context.hasTypes()) {
			if (context.isAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.editor)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canViewMembers(context: PermissionContextService, memberId?: number): Promise<boolean> {
		if (context.isSuperAdmin()) return Promise.resolve(false);
		// ETT-216 Restrict current context user from performing actions on himself as member.
		if (context.isSameUser(memberId)) { return Promise.resolve(false); }
		if (context.hasTypes()) {
			if ((context.isCompanyCustomer() || context.isAgent() || context.isMember()) && context.roleIsEqualOrBelow(CompanyMemberRole.viewer)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canAddEditDeleteMember(context: PermissionContextService, memberId?: number): Promise<boolean> {
		if (context.hasTypes()) {
			if ((context.isCompanyCustomer() || context.isAgent() || context.isMember()) && context.roleIsEqualOrBelow(CompanyMemberRole.admin)) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canInvitePerformer(context: PermissionContextService): Promise<boolean> {
		if (context.hasTypes()) { // Payer can be or a company, or an individual payer.
			if (context.isCustomer() && (((context.currentContext as User)?.isCustomer) || context.roleIsEqualOrBelow(CompanyMemberRole.admin))) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canEditProfile(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return this.isSuperAdmin(context);
		if (context.hasTypes()) {
			if (context.isTalent()
			|| context.isIndividualCustomer()
			|| (context.isCustomerOrAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.owner, true))) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canEditProfileTaxationInfo(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return this.isSuperAdmin(context);
		if (context.hasTypes()) {
			if ((context.isTalent()
			|| context.isIndividualCustomer()
			|| (context.isCustomerOrAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.owner, true)))
			&& context.hasAccessToProfileStep('taxation')) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}

	static canEditProfileFinancialInfo(context: PermissionContextService): Promise<boolean> {
		if (context.isSuperAdmin()) return this.isSuperAdmin(context);
		if (context.hasTypes()) {
			if ((context.isTalent()
			|| context.isIndividualCustomer()
			|| (context.isCustomerOrAgent() && context.roleIsEqualOrBelow(CompanyMemberRole.owner, true)))
			&& context.hasAccessToProfileStep('banking')) {
				return Promise.resolve(true);
			}
		}
		return Promise.resolve(false);
	}
}
