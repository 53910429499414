/** *********************************** Theme schemas ************************************* */

export enum ThemeMode {
	Light,
	Dark,
}
export interface Theme {
	name: string; // 'name' has to match the $theme-colors() from the _variables.scss
	mode: ThemeMode;
	active: boolean;
}
export interface IsLoadingType {
	[key: string]: boolean;
}

/** ********************************** Header schemas ************************************* */

export enum HeaderType {
	FrontendAuthorizedUser,
	FrontendLandingPage,
}

export enum NavigationType {
	Header,
	Sidenav,
	Footer,
}

export enum SidenavType {
	Default,
	Notifications,
	OrdersFilter,
	UsersFilter,
	CompaniesFilter
}

export enum SidenavPosition {
	End = 'end',
	Start = 'start'
}
