/**
 * Creates a generalized container for dynamically rendered elements, as tooltip, popup etc.
 * @link https://medium.com/angular-in-depth/loading-components-dynamically-in-angular-cd13b9fdb715
 * @link https://stackblitz.com/edit/dynamic-ng-content-lxqkya
 */

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnsureModuleLoadedOnceGuard } from '@app/helpers/module-import.guard';
import { DynamicElementsContainerComponent } from '@app/components/dynamic-elements-container/dynamic-elements-container.component';
import { DynamicElementsContainerDirective } from '@app/components/dynamic-elements-container/dynamic-elements-container.directive';
import { DynamicElementsContainerService } from '@app/components/dynamic-elements-container/dynamic-elements-container.service';

@NgModule({
	declarations: [
		DynamicElementsContainerComponent,
		DynamicElementsContainerDirective
	],
	imports: [CommonModule],
	exports: [
		DynamicElementsContainerComponent,
		DynamicElementsContainerDirective
	],
	entryComponents: [
		DynamicElementsContainerComponent // Otherwise, it won’t be able to be loaded during runtime.
	],
	providers: [
		DynamicElementsContainerService
	]
})
export class DynamicElementsContainerModule extends EnsureModuleLoadedOnceGuard {
	constructor(@Optional() @SkipSelf() parentModule: DynamicElementsContainerModule) {
		super(parentModule);
	}
}
