// Permission function type.
import { PermissionContextService } from '@app/services/permissions/permission-context.service';
import { PermissionValidationService } from '@app/services/permissions/permission-validation.service';
import { CompanyMemberRole, CompanyType } from '@feature/companies/shared/company.model';
import { NavigationInfo } from '@app/models/navigation.model';

export type PermissionFunction = (context: PermissionContextService) => Promise<boolean>;

// Permission model.
export class PermissionsObject {
    [name: string]: PermissionFunction;
}

// Permission type model (includes role + permission).
export interface PermissionTypesObject {
    [type: string]: {
        [role: string]: Array<PermissionsObject>
    };
}

// ETT-133 Permissions check based on the given table.
// Combination of permissions: on the app level based on the type of user (customer, agent, etc.) and company role.
export const appPermissions: PermissionTypesObject = {
	[CompanyType.customer]: {
		[CompanyMemberRole.viewer]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
		],
		[CompanyMemberRole.editor]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canCreatePayment: PermissionValidationService.canCreatePayment },
			{ canImportPayment: PermissionValidationService.canImportPayment },
			{ canInvitePerformer: PermissionValidationService.canInvitePerformer }
		],
		[CompanyMemberRole.admin]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canCreatePayment: PermissionValidationService.canCreatePayment },
			{ canImportPayment: PermissionValidationService.canImportPayment },
			{ canAddEditDeleteMember: PermissionValidationService.canAddEditDeleteMember },
			{ canInvitePerformer: PermissionValidationService.canInvitePerformer }
		],
		[CompanyMemberRole.owner]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canCreatePayment: PermissionValidationService.canCreatePayment },
			{ canImportPayment: PermissionValidationService.canImportPayment },
			{ canAddEditDeleteMember: PermissionValidationService.canAddEditDeleteMember },
			{ canInvitePerformer: PermissionValidationService.canInvitePerformer },
			{ canEditProfile: PermissionValidationService.canEditProfile }
		]
	},
	[CompanyType.agent]: {
		[CompanyMemberRole.viewer]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canViewClients: PermissionValidationService.canViewClients }
		],
		[CompanyMemberRole.editor]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canViewClients: PermissionValidationService.canViewClients },
			{ canUploadDocsToClient: PermissionValidationService.canUploadDocsToClient },
			{ canAddEditDeleteClient: PermissionValidationService.canAddEditDeleteClient },
		],
		[CompanyMemberRole.admin]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canViewClients: PermissionValidationService.canViewClients },
			{ canUploadDocsToClient: PermissionValidationService.canUploadDocsToClient },
			{ canAddEditDeleteClient: PermissionValidationService.canAddEditDeleteClient },
			{ canAddEditDeleteMember: PermissionValidationService.canAddEditDeleteMember }
		],
		[CompanyMemberRole.owner]: [
			{ canViewPayment: PermissionValidationService.canViewPayment },
			{ canViewClients: PermissionValidationService.canViewClients },
			{ canUploadDocsToClient: PermissionValidationService.canUploadDocsToClient },
			{ canAddEditDeleteClient: PermissionValidationService.canAddEditDeleteClient },
			{ canAddEditDeleteMember: PermissionValidationService.canAddEditDeleteMember },
			{ canEditProfile: PermissionValidationService.canEditProfile }
		],
	},
	[CompanyType.talent]: {
		[CompanyMemberRole.owner]: [
			{ canEditProfile: PermissionValidationService.canEditProfile }
		]
	}
};

/**
 * The idea behind these list is to restrict access to the corresponding page.
 * PermissionGuard check permissions of a current context and match them to the current page URL.
 * Permissions check is based on the appPermissions table (above) per user role.
 *
 * E.g. User can be in several contexts. Let's say one of the contexts is agent.
 * Agent should not being able to access payment creation screen.
 *
 * If page doesn't match the permissions, the user is redirected to a homepage.
 * By default, page access is allowed.
 *
 * IMPORTANT: Don't add the empty permission property if there is no need in checking it.
 * Additional explanation is available inside the PermissionGuard.
 */
export const appPermissionGuards: Array<NavigationInfo> = [
	{
		name: 'Payments',
		path: '/orders',
		children: [
			{
				name: 'Create Payment',
				path: '/orders/new',
				permissions: [
					{ canCreatePayment: PermissionValidationService.canCreatePayment }
				]
			}
		],
		permissions: [
			{ canViewPayment: PermissionValidationService.canViewPayment }
		],
	},
	{
		name: 'Users',
		path: '/users',
		icon: 'people',
		permissions: [
			{ isSuperAdmin: PermissionValidationService.isSuperAdmin }
		],
	},
	{
		name: 'Team',
		path: '/companies/team',
		permissions: [
			{ canViewMembers: PermissionValidationService.canViewMembers },
			// { canEditMembers: PermissionValidationService.canAddEditDeleteMember }
		],
	},
	{
		name: 'Contributors',
		path: '/companies',
		children: [
			{
				name: 'Clients',
				path: '/companies/clients',
				permissions: [
					{ canViewClients: PermissionValidationService.canViewClients }
				],
			},
			{
				name: 'Agents',
				path: '/companies/agents',
				permissions: [
					{ hasAgents: PermissionValidationService.hasAgents }
				],
			},
		],
		permissions: [
			{ canViewContributors: PermissionValidationService.canViewContributors },
		],
	},
	{
		name: 'Analytics & Reports',
		path: '/reports',
		children: [
			{
				name: 'Pay Report',
				path: '/reports',
				permissions: [
					{ isSuperAdmin: PermissionValidationService.isSuperAdmin }
				],
			},
			{
				name: 'Collect Report',
				path: '/reports/invoice',
				permissions: [
					{ isSuperAdmin: PermissionValidationService.isSuperAdmin }
				],
			},
		],
		permissions: [{
			isSuperAdmin: PermissionValidationService.isSuperAdmin
		}],
	},
	{
		name: 'Profile Info',
		path: '/profile/details',
		permissions: [
			{ canEditProfile: PermissionValidationService.canEditProfile }
		]
	},
	{
		name: 'Personal Settings',
		path: '/profile/taxation',
		permissions: [
			{ canEditProfile: PermissionValidationService.canEditProfile },
			{ canEditProfileTaxationInfo: PermissionValidationService.canEditProfileTaxationInfo }
		]
	},
	{
		name: 'Financial Settings',
		path: '/profile/banking',
		permissions: [
			{ canEditProfile: PermissionValidationService.canEditProfile },
			{ canEditProfileFinancialInfo: PermissionValidationService.canEditProfileFinancialInfo }
		]
	},
	{
		name: 'Preferences',
		path: '/profile/preferences',
		permissions: [
			{ canViewClients: PermissionValidationService.canViewClients }
		]
	}
];
