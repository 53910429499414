import { Component, Injectable, Type, Injector, Renderer2, TemplateRef, ComponentRef, ViewContainerRef } from '@angular/core';

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable({ providedIn: 'root' })
export class DynamicElementsContainerService {

	/** *********************************** Declarations ************************************* */

	private _listeners: Array<ComponentRef<any>>;
	private _dynamicElementsContainer: ViewContainerRef;
	private _renderer: Renderer2;

	/** ********************************** Getter/Setter ************************************* */

	get dynamicElementsContainer(): ViewContainerRef { return this._dynamicElementsContainer; }
	set dynamicElementsContainer(container: ViewContainerRef) { this._dynamicElementsContainer = container; }
	get renderer(): Renderer2 { return this._renderer; }
	set renderer(value: Renderer2) { this._renderer = value; }
	get listeners(): Array<any> { return this._listeners; }
	set listeners(fn: Array<any>) { this._listeners = fn; }

	/** *********************************** Constructor ************************************** */

	constructor() {
		this.listeners = [];
	}

	/** ************************************* Methods **************************************** */

	/** Generate the random ID */
	public generateId(length = 9): string {
		// eslint-disable-next-line no-magic-numbers
		return Math.random().toString(36).substring(2, length);
	}

	/**
     * Creates a single @Component and inserts its host view into the dynamic elements container
	 * @template T - The generic type parameter.
     * @param {Type<any>} component – The factory to use.
     * @param {Injector} injector – The injector to use as the parent for the new component.
     * @param {Content<T>} content - The desired content of the @Component (can be string, TemplateRef or @Component.)
     * @returns {ComponentRef<T>} The new component instance, containing the host view.
     */
	public loadComponent<T>(component: Type<any>, injector?: Injector, content?: Content<T>): ComponentRef<T> {
		this.dynamicElementsContainer.clear();

		const ngContent = this.resolveNgContent(content);
		const componentRef = this.dynamicElementsContainer.createComponent(component, { injector: injector, projectableNodes: ngContent });
		componentRef.hostView.detectChanges();

		this.listeners.push(componentRef); // Record the created @Component.
		return componentRef;
	}

	/**
     * Generate content depending on the data type passed
     * Note: it's very important to let Angular know to detect changes after the view update.
     * @link https://stackoverflow.com/a/61970860/15391077
     */
	public resolveNgContent<T>(content: Content<T>): Array<Array<any>> { // We can pass string, template or component.
		if (content == null) return;

		let returnValue: any = null;
		// We check that our Input() is a string and create a help node of the renderer service.
		if (typeof content === 'string') {
			const element = this.renderer.createElement('div');
			this.renderer.setProperty(element, 'innerHTML', content); // HTML, not text element.
			returnValue = [[element]];
		}

		// Handling TemplateRef.
		// This will give us reference to a View object that has one special property — rootNodes.
		// The rootNodes is an array of DOM nodes that are extracted from the template.
		if (content instanceof TemplateRef) {
			const viewRef = content.createEmbeddedView(null);
			viewRef.detectChanges();
			// In earlier versions, you may need to add this line
			// this.appRef.attachView(viewRef);
			returnValue = [viewRef.rootNodes];
		}

		// TODO: add functionality for @Components
		if (content instanceof Component) {
			// Handling a @Component.
			// @link https://stackoverflow.com/a/46043837/15391077
			// const factory = this.resolver.resolveComponentFactory(this.content);
			// const componentRef = factory.create(this.injector);
			// componentRef.changeDetectorRef.detectChanges();
			// // In earlier versions, you may need to add this line
			// // this.appRef.attachView(componentRef.hostView);
			// returnValue = [ [componentRef.location.nativeElement] ];

			// Add this as new Input and in case it has its own Input() or Output() properties.
			// if (this.inputs) {
			//     Object.keys(this.inputs).forEach(input => {
			//         componentRef.instance[input] = this.inputs[input];
			//     });
			//     componentRef.changeDetectorRef.detectChanges();
			//     return [[componentRef.location.nativeElement]];
			// }
		}

		// eslint-disable-next-line consistent-return
		return returnValue;
	}

	/** ************************************* Destroy **************************************** */

	public destroy() {
		if (this.listeners != null) {
			this.listeners.forEach((component: ComponentRef<any>) => component.destroy()); // Call the basic @Component destroy().
		}
	}

	onDestroy() {
		this.destroy();
		this.dynamicElementsContainer.clear();
		this.dynamicElementsContainer = null; // Undefined (@link https://stackoverflow.com/a/7452352/15391077)
	}
}
