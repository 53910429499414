import { OrderTemplate } from '@feature/orders/shared/order-template.model';
import { Address, EmailCC, Profile } from '@feature/profiles/shared/profile.model';
import { CompanyPair } from '@feature/companies/shared/company.model';
import { CustomFieldValue } from '@app/components/custom-fields/shared/custom-field.model';
import { Document } from '@feature/document-center/shared/document.model';

// Exclude parameters that should not be used in counter calculation.
// @note We can exclude the `agentIds` parameter, because it's not represented in any of the filter fields directly.
export const FILTERS_TO_EXCLUDE_FROM_COUNT: Array<string> = [
	'size',
	'sort',
	'page',
	'preview',
];

export enum AgentRequestStatus {
	ready = 'ready',
	declined = 'declined',
	reSent = 're_sent',
	inProgress = 'in_progress',
	deactivated = 'deactivated'
}

export enum AgentRequestAction {
	ready = 'ready',
	declined = 'declined',
	reSend = 're_send',
	inProgress = 'in_progress',
	deactivated = 'deactivated',
	reactivate = 'reactivate'
}

export class AgentRequestActionWithUser {
	agentDetails: UserAgentRef;
	action: AgentRequestAction;

	constructor(agentDetails: UserAgentRef, action: AgentRequestAction) {
		this.agentDetails = agentDetails;
		this.action = action;
	}
}

export enum UserBulkAction {
	deactivateUser = 'DeactivateUser',
	activateUser = 'ActivateUser'
}

export type AnyUser = User | UserPair | OrderUser;

export class User {
	id: number;

	firstName: string;
	middleName?: string;
	lastName: string;
	fullName: string;
	email: string;
	isNew: boolean;
	isBanned: boolean;
	isMigrated: boolean;
	isIndividual: boolean;
	isCompany: boolean;
	isMember: boolean;

	// @see {@link https://stackoverflow.com/a/34602679/15391077}
	// @see {@link https://stackoverflow.com/a/15171030/15391077}
	// @note Use for date based on timeZone extraction: new Date().toLocaleString('en', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
	timeZone?: string;

	isAgent: boolean;
	isTalent: boolean;
	isCustomer: boolean;
	isAdmin: boolean;

	canBeAgent: boolean;
	canBeCustomer: boolean;

	signupStep: string; // Has to be pulled separately for individual (don't know how to pull it the another way).

	payeeOrdersCount: number;

	individual?: Individual;

	roles?: Array<UserRole>;
	agents?: Array<UserAgentRef>;
	clients?: Array<UserAgentRef>;
	emailCCs?: Array<EmailCC>;

	jwt?: string;

	// To be used in AnyUser.
	billAddress?: any;
	company?: any;

	checked?: boolean;
	status?: boolean;

	// Role with min level - means the highest authority.
	get minRole(): UserRole {
		if (this.roles) {
			return this.roles.reduce((prev, curr) => {
				return prev.level < curr.level ? prev : curr;
			});
		}
		return null;
	}

	// Role with max level - means the lowest authority.
	get maxRole(): UserRole {
		if (this.roles) {
			return this.roles.reduce((prev, curr) => {
				return prev.level > curr.level ? prev : curr;
			});
		}
		return null;
	}
}

export class UserRole {
	id: number;
	level: number;
	alias: string;
	title: string;
	description: string;

	constructor(alias: string) {
		this.alias = alias;
	}
}

export enum UserSortType {
	none = 'none',
	all = 'all',
	admin = 'admin',
	agent = 'agent',
	talent = 'talent',
	customer = 'customer'
}

export class OrderUser {
	firstName?: string;
	middleName?: string;
	lastName?: string;
	fullName: string;
	email: string;
	billAddress: string;
	shipAddress: string;
	company: string;

	// To be used in AnyUser.
	address?: never;
	phone?: never;
	emailCCs?: never;
	individual?: never;
}

export class UserAgentRef {
	id: number;
	agentId: number;
	agentFullName: string;
	agentEmail: string;
	agencyId: number;
	agencyName: string;
	clientId: number;
	clientFullName: string;
	clientFirstName: string;
	clientLastName: string;
	clientEmail: string;
	clientDefaultTemplate: OrderTemplate;
	requestKey: string;
	status: AgentRequestStatus;
	updatedDate: Date;
	customFieldValues?: Array<CustomFieldValue>;
}

export class PayeeAgentPair {
	payee: UserPair;
	agent?: UserPair;
	agency?: CompanyPair;
	payeeCompany?: CompanyPair;
	isVendor?: boolean = false;

	get id(): string { return this.payee?.id?.toString() + (this.agent ? '-' + this.agent?.id : ''); }
	get text(): string {
		if (this.isVendor) {
			// @note Vendor can be an international payer, an individual payer/performer or a company payer.
			// International and individual payers don't have an LLC name. Use the user's name instead.
			// Companies don't have an LLC name but must provide a company name. Use it instead.
			return (this.payeeCompany?.name?.length > 0 ? this.payeeCompany?.name : this.payee?.fullName) + ` (Service Provider)`;
		}

		const receiverType = this.agency ? this.agency?.name : 'Direct pay';
		return this.payee?.fullName + ` (${receiverType} - ${this.payee?.defaultTemplate?.title})`;
	}
	get address(): Address {
		if (this.payeeCompany != null) return this.payeeCompany?.address;
		return this.agency ? this.agency?.address : this.payee?.address;
	}
	// Always use the actual PAYEE's order template (union) - never the agent's.
	get defaultTemplate(): OrderTemplate { return this.payee?.defaultTemplate; }
}

export class UserPair {
	id: number;

	firstName: string;
	middleName?: string;
	lastName: string;
	fullName: string;
	email: string;
	timeZone?: string;
	isNew: boolean;
	isBanned: boolean;
	isMigrated: boolean;
	isIndividual: boolean;
	isCompany: boolean;
	isMember: boolean;

	isAgent: boolean;
	isTalent: boolean;
	isCustomer: boolean;
	isAdmin: boolean;

	canBeAgent: boolean;
	canBeCustomer: boolean;

	signupStep: string; // Has to be pulled separately for individual (don't know how to pull it the another way).

	payeeOrdersCount: number;

	orderTemplates?: Array<OrderTemplate>;
	defaultTemplate?: OrderTemplate;

	address?: never;
	individual?: Individual;
	emailCCs?: Array<EmailCC>;

	// To be used in AnyUser.
	billAddress?: any;
	company?: any;
}

export interface SignUpStep {
	alias: string;
	stepName: string;
	routerLink: string;
	isActive: boolean;
	isFinished: boolean;
	isDisabled: boolean;
	isHidden: boolean;
}

export class Individual {
	id: number;
	userId: number;
	userIsNew: boolean;
	userRoles?: Array<UserRole>;
	profile: Profile;
}

export class Auth0UserVerification {
	email: string;
	emailVerified: boolean;
}

export class AddUserResponse {
	canAdd: boolean;
	errorMessage?: string;
	warningMessage?: string;
	user?: User;
}

export class UserWithDocument {
	user: User;
	document: Document;
}

export class UserBulkActionInput {
	action: UserBulkAction;
	users: Array<number>;
}

export class UserBulkActionOutput {
	affectedUsers: Array<User>;
	unaffectedUsers: Array<number>;
}

export interface TabStep {
	index?: number;
	name?: string;
	isSelected?: boolean;
	hasErrors?: boolean;
}

export class UserContextRef {
	userId?: number;
	profile?: Profile;
}
