import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

/**
 * Additional functionality over Material Design Snackbar
 * @link https://anglesvar.medium.com/add-custom-css-to-material-snackbar-91ed36b48fba
 * @link https://edupala.com/how-to-implement-angular-material-snackbar/
 */
@Injectable({ providedIn: 'root' })
export class SnackBarService {

	/** *********************************** Declarations ************************************* */

	public config: MatSnackBarConfig;
	public matSnackBarRef: MatSnackBarRef<any>;

	/** *********************************** Constructor ************************************** */

	constructor(private _matSnackBar: MatSnackBar) {
		this.config = {
			duration: 6000,
			horizontalPosition: 'end',
			verticalPosition: 'bottom'
		};
	}

	/** ************************************* Methods **************************************** */

	/** Open success snackbar */
	public success(message: string, action?: string, duration?: number): MatSnackBarRef<any> {
		this.config.panelClass = 'success';
		if (duration != null) this.config.duration = duration;
		return this._openMatSnackBar(message, action);
	}

	/** Open error snackbar */
	public error(message: string, action?: string, duration?: number): MatSnackBarRef<any> {
		this.config.panelClass = 'error';
		if (duration != null) this.config.duration = duration;
		return this._openMatSnackBar(message, action);
	}

	/** Open warning snackbar */
	public warn(message: string, action?: string, duration?: number): MatSnackBarRef<any> {
		this.config.panelClass = 'warn';
		if (duration != null) this.config.duration = duration;
		return this._openMatSnackBar(message, action);
	}

	/** Open info snackbar */
	public info(message: string, action?: string, duration?: number): MatSnackBarRef<any> {
		this.config.panelClass = 'info';
		if (duration != null) this.config.duration = duration;
		return this._openMatSnackBar(message, action);
	}

	/** Open native Material SnackBar */
	private _openMatSnackBar(message: string, action?: string): MatSnackBarRef<any> {
		this.matSnackBarRef = this._matSnackBar.open(message, (action || 'x'), this.config);
		return this.matSnackBarRef;
	}
}
