import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
	AddUserResponse,
	Auth0UserVerification,
	User,
	UserWithDocument,
	Individual,
	PayeeAgentPair,
	UserAgentRef,
	UserBulkActionInput,
	UserBulkActionOutput,
} from '@feature/users/shared/user.model';
import { environment } from '@env/environment';
import { BaseHttpService } from '@app/services/base-http.service';
import { Page, PageRequest } from '@app/models/page.model';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseHttpService {
	/** *********************************** Declarations ************************************* */

	// Mostly needed for CRUD operations on the user.
	options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

	/** *********************************** Constructor ************************************** */

	constructor(private _http: HttpClient) {
		super(_http);
	}

	/** ************************************* Methods **************************************** */

	public getPayeeAgentPairs(filter: string): Observable<PayeeAgentPair[]> {
		filter = encodeURIComponent(filter);
		return this._http.get<PayeeAgentPair[]>(`${environment.config.apiUrl}/payee-agent-pairs/${filter}`);
	}

	public getById(id: any): Observable<User> {
		id = encodeURIComponent(id);
		return this._http.get<User>(`${environment.config.apiUrl}/users/${id}`, this.options);
	}

	public getByEmail(email: string): Observable<User> {
		email = encodeURIComponent(email);
		return this._http.get<User>(`${environment.config.apiUrl}/users/email/${email}`);
	}

	public getClients(agencyId: number, pageable?: PageRequest, search?: string): Observable<Page<UserAgentRef>> {
		return this._http.get<Page<UserAgentRef>>(`${environment.config.apiUrl}/companies/${agencyId}/clients`, { params: this.params(pageable, search) });
	}

	public getClient(agencyId: number, clientId: number): Observable<UserAgentRef> {
		return this._http.get<UserAgentRef>(`${environment.config.apiUrl}/companies/${agencyId}/clients/${clientId}`);
	}

	public getUsers(type: string, pageable?: PageRequest, search?: string): Observable<Page<UserWithDocument>> {
		return this._http.get<Page<UserWithDocument>>(`${environment.config.apiUrl}/users/type/${type}`, { params: this.params(pageable, search) });
	}

	public getAgents(clientId: number, clientIds?: Array<number>): Observable<UserAgentRef[]> {
		// ETT-136 Allow to search for agent-client pairs.
		let parameters = new HttpParams();
		if (clientIds != null && clientIds?.length > 0) {
			clientIds.map((id: number) => parameters = parameters.append('clients', id));
		}
		return this._http.get<UserAgentRef[]>(`${environment.config.apiUrl}/users/${clientId}/agents`, { params: parameters });
	}

	public canAddClient(agencyId: number, talentEmail: string): Observable<AddUserResponse> {
		talentEmail = encodeURIComponent(talentEmail);
		return this._http.get<AddUserResponse>(`${environment.config.apiUrl}/companies/${agencyId}/can-add-talent/${talentEmail}`);
	}

	public addClient(agencyId: number, clientId: number): Observable<UserAgentRef> {
		return this._http.put<UserAgentRef>(`${environment.config.apiUrl}/companies/${agencyId}/clients/${clientId}`, null);
	}

	public acceptAgent(key: string): Observable<UserAgentRef> {
		return this._http.put<UserAgentRef>(`${environment.config.apiUrl}/agent-request/${key}/accept`, null);
	}

	public declineAgent(key: string): Observable<UserAgentRef> {
		return this._http.put<UserAgentRef>(`${environment.config.apiUrl}/agent-request/${key}/decline`, null);
	}

	public updateAgentRef(ref: UserAgentRef, step?: string): Observable<UserAgentRef> {
		let parameters = new HttpParams();
		parameters = parameters.append('step', step);
		return this._http.put<UserAgentRef>(`${environment.config.apiUrl}/agent-request/${ref.id}`, ref, { params: parameters });
	}

	public createUser(user: User, role: string): Observable<User> {
		if (role.toLocaleLowerCase() === 'talent') {
			return this.createTalent(user);
		} else if (role.toLocaleLowerCase() === 'customer') {
			return this.createCustomer(user);
		} else if (role.toLocaleLowerCase() === 'admin') {
			return this.createAdministrator(user);
		}
		return of(null);
	}

	public createTalent(talent: User, companyContextId?: number): Observable<User> {
		let performerParams = new HttpParams();
		performerParams = performerParams.append('companyContextId', (companyContextId != null ? companyContextId.toString() : ''));
		return this._http.post<User>(`${environment.config.apiUrl}/payees`, talent, { params: performerParams });
	}

	public createCustomer(customer: User): Observable<User> {
		return this._http.post<User>(`${environment.config.apiUrl}/payers`, customer, this.options);
	}

	public createPerformer(performer: User, companyContextId?: number, needToSendEmail?: boolean): Observable<User> {
		let performerParams = new HttpParams();
		performerParams = performerParams.append('companyContextId', (companyContextId != null ? companyContextId.toString() : ''));
		performerParams = performerParams.append('needToSendEmail', (needToSendEmail != null ? String(needToSendEmail) : 'true'));
		return this._http.post<User>(`${environment.config.apiUrl}/performers`, performer, { params: performerParams });
	}

	public createAdministrator(customer: User): Observable<User> {
		return this._http.post<User>(`${environment.config.apiUrl}/administrators`, customer, this.options);
	}

	/**
	 * Deletes a user
	 * @param {number} userId - The user's unique identifier to delete
	 * @returns {Observable<any>}
	 */
	public delete(userId: number): Observable<any> {
		return this._http.delete(`${environment.config.apiUrl}/users/${userId}`);
	}

	/**
	 * Updates the information associated with a user
	 * @param {User} user - The user object containing updated information.
	 * @param {boolean} [isAccountPage=false] - An optional flag indicating the update is part of the account page.
	 * @returns {Observable<User>}
	 */
	public updateUser(user: User, isAccountPage = false): Observable<User> {
		let performerParams = new HttpParams();
		if (isAccountPage) performerParams = performerParams.append('isAccountPage', 'true');
		return this._http.put<User>(`${environment.config.apiUrl}/users/${user.id}`, user, { params: performerParams });
	}

	/**
	 * Resends the verification email to a user
	 * @param {number} userId - The user's unique identifier to send the verification email to.
	 * @returns {Observable<void>}
	 */
	public resendVerifyEmail(userId: number): Observable<void> {
		return this._http.put<void>(`${environment.config.apiUrl}/users/${userId}/verify-email`, null);
	}

	/**
	 * Checks if the user's email is verified
	 * @param {number} userId - The user's unique identifier to send the verification email to.
	 * @returns {Observable<Auth0UserVerification>}
	 */
	public isEmailVerified(userId: number): Observable<Auth0UserVerification> {
		return this._http.get<Auth0UserVerification>(`${environment.config.apiUrl}/users/${userId}/verify-email`);
	}

	/**
	 * Retrieves an individual based on the provided user id
	 * @param {number} userId - The user's unique identifier whose individual information is to be retrieved.
	 * @returns {Observable<Individual>}
	 */
	public getIndividualByUserId(userId: number): Observable<Individual> {
		return this._http.get<Individual>(`${environment.config.apiUrl}/individuals/users/${userId}`);
	}

	/**
	 * Creates an individual profile reference
	 * @param {Individual} individual - An individual containing information to be inserted.
	 * @returns {Observable<Individual>}
	 */
	public createIndividual(individual: Individual): Observable<Individual> {
		return this._http.post<Individual>(`${environment.config.apiUrl}/individuals/users/${individual.userId}`, individual, this.options);
	}

	/**
	 * Updates an individual profile reference
	 * @param {Individual} individual - An individual containing the updated information.
	 * @param {string} [step] - An optional step identifier for tracking the update process.
	 * @returns {Observable<Individual>}
	 */
	public updateIndividual(individual: Individual, step?: string): Observable<Individual> {
		let profileParams = new HttpParams();
		profileParams = profileParams.append('step', (step != null ? step : null));
		return this._http.put<Individual>(`${environment.config.apiUrl}/individuals/${individual.id}`, individual, { params: profileParams });
	}

	public doesClientExist(agentId: number, client: User, companyContextId?: number, agencyId?: number): Observable<boolean> {
		let performerParams = new HttpParams();
		performerParams = performerParams.append('agentId', (agentId != null ? agentId.toString() : ''));
		performerParams = performerParams.append('agencyId', (agencyId != null ? agencyId.toString() : ''));
		performerParams = performerParams.append('companyContextId', (companyContextId != null ? companyContextId.toString() : ''));
		return this._http.post<boolean>(`${environment.config.apiUrl}/agent/does-client-exist`, client, { params: performerParams });
	}

	public canAddPerformer(email: string): Observable<AddUserResponse> {
		return this._http.get<AddUserResponse>(`${environment.config.apiUrl}/can-add-performer/${email}`, this.options);
	}

	public bulkAction(input: UserBulkActionInput): Observable<UserBulkActionOutput> {
		return this._http.put<UserBulkActionOutput>(`${environment.config.apiUrl}/users/bulk-action`, input, this.options);
	}

	// @todo Test if it's required.
	public resetPassword(userEmail: string) {
		// if (userEmail === undefined) return;
		//
		// let params = new HttpParams();
		// params = params.append('client_id', environment.auth0.clientId)
		// .append('email', userEmail)
		// .append('connection', environment.auth0.dbConnectionName);
		// return this._http.post(`${environment.auth0.authDomain}/dbconnections/change_password`, params)
		// .pipe((response: any) => {
		// console.log(response);
		// });
	}
}
