import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/helpers/auth.guard';
import { CustomPreloadingStrategy } from '@app/helpers/preloading-strategy.interceptor';
import { environment } from '@env/environment';

/**
 * Auxiliary routes preloading to reduce Angular App loading time
 * @note Routes order matter. In a current structure, basic/empty URL will load dashboard layout for
 *
 * @link https://www.educative.io/blog/angular-routing-guide
 * @link https://javascript.plainenglish.io/angular-router-children-or-loadchildren-a74a9593af07
 * @link https://angular.io/guide/router#module-import-order-matters
 * @link https://dev.to/christiankohler/bundling-angular-modules-4eco - Lazy-loading and modules loading explained the best.
 * @link https://indepth.dev/posts/1235/how-to-reuse-common-layouts-in-angular-using-router-2
 * @link https://blog.bitsrc.io/lazy-loading-auxiliary-routes-with-angular-why-and-how-9ceb2ddc6cae -> Auxiliary routes
 */
const routes: Routes = [
	{ path: '', loadChildren: () => import('@layout/dashboard-layout/dashboard-layout.module').then((m) => m.DashboardLayoutModule) },
	{ path: '', loadChildren: () => import('@feature/redirects/redirect.module').then(m => m.RedirectModule) },
	{ path: '**', redirectTo: 'dashboard' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: environment.debugMode,
			useHash: false,
			// @link https://www.concretepage.com/angular-2/angular-custom-preloading-strategy#Custom-Preloading
			preloadingStrategy: CustomPreloadingStrategy,
			scrollPositionRestoration: 'top', // Otherwise the header is not at the top on refresh. @link https://bit.ly/3Uiohl7
			anchorScrolling: 'enabled', // When enabled, scrolls to the anchor element when the URL has a fragment.
			// eslint-disable-next-line no-magic-numbers
			scrollOffset: [0, 10], // Configures the scroll offset the router.
			onSameUrlNavigation: 'reload', // @link https://bit.ly/2P4R4Lf
			// paramsInheritanceStrategy: 'always',
		})
	],
	exports: [RouterModule],
	providers: [CustomPreloadingStrategy],
})
export class AppRouting { }
