import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ThemeService } from '@app/services/theme.service';
import { CoreModule } from '@app/core.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@app/helpers/jwt.interceptor';

import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import { TitleStrategy } from '@angular/router';
import { TemplateTitleStrategy } from '@app/services/title.service';
import { MaterialModule } from '@shared/material.module';
import { CredentialsInterceptor } from '@app/helpers/credentials.interceptor';
import { ErrorInterceptor } from '@app/helpers/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'environments/environment';

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		BrowserModule, // Already contains CommonModule.
		BrowserAnimationsModule,
		HttpClientModule, // HttpClient for getting the API data.
		CoreModule,
		// @todo Remove the for modules import and test after updating to Angular 15.
		// @note FormBuilder classes must be provided in root until Angular 15.
		// @see {@link https://github.com/angular/angular/commit/02691a74bb9889bae42f1c1c37b70c946f322dd5}
		FormsModule,
		ReactiveFormsModule,
		// Peculiar Material issue that require direct MaterialModule import.
		// Specifically, the problem is related to MatSnackBar injector issue.
		// @see {@link https://github.com/angular/components/issues/8375#issuecomment-344185966}
		MaterialModule,
		AppRouting,
		// @see {@link https://github.com/mzuccaroli/angular-google-tag-manager?tab=readme-ov-file#custom-configuration-and-gtm-environments}
		GoogleTagManagerModule.forRoot({
			id: environment.googleConfig.googleTag
		})
	],
	providers: [
		ThemeService,
		// Locale will only affect representation format and not timezone.
		// @see {@link https://blog.theodo.com/2021/10/angular-localized-date-pipe/}
		{ provide: LOCALE_ID, useValue: 'en-US' },
		// @note ETT-504 Content-Security-Policy with Nonce (to boost website's security and performance) is disabled.
		// @see {@link https://sudolabs.com/insights/level-up-your-web-analytics-and-security-with-gtm-and-csp-in-next-js}
		// { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
		{ provide: TitleStrategy, useClass: TemplateTitleStrategy }
	],
})
export class AppModule {}
